<template>
  <GuestLayout>
    <!-- request reset -->
    <q-form
      v-if="state === 'request' || state == 'requesting'"
      ref="formRef"
      greedy
      class="request-view"
    >
      <q-card style="padding: 100px 150px">
        <q-card-section class="text-center">
          <div class="text-h4">
            {{ $t("password-reset.request.title") }}
          </div>
          {{ $t("password-reset.request.message") }}
        </q-card-section>
        <q-card-section class="q-pa-sm">
          <q-input
            v-model="adminEmail"
            outlined
            :label="$t('password-reset.request.label')"
            type="email"
            autofocus
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val, rules) => rules.email(val) || $t('input.enter-valid-email'),
            ]"
            lazy-rules
            class="admin-email"
            :readonly="!!resetToken"
            input-class="admin-email-input"
          />
        </q-card-section>
        <q-card-actions align="right" class="q-gutter-md">
          <router-link :to="{ name: 'login' }">
            {{ $t("password-reset.request.back-button") }}
          </router-link>
          <q-btn
            :label="$t('password-reset.request.request-button')"
            :loading="state === 'requesting'"
            class="request-button"
            color="primary"
            @click="request"
          />
        </q-card-actions>
      </q-card>
    </q-form>
    <!-- reset was requested -->
    <q-card
      v-else-if="state === 'requested'"
      style="padding: 20px"
      class="requested-view"
    >
      <q-card-section class="text-center q-gutter-md">
        <div class="text-h4">
          {{ $t("password-reset.requested.title") }}
        </div>
        <div>
          {{ $t("password-reset.requested.message", { email: adminEmail }) }}
        </div>
        <div>{{ $t("password-reset.requested.close") }}</div>
        <q-card-section class="text-center">
          <q-icon name="mdi-mailbox-open-up" size="150px" color="primary" />
        </q-card-section>
      </q-card-section>
    </q-card>
    <!-- request was expired -->
    <q-card
      v-else-if="state === 'expired'"
      style="padding: 20px"
      class="expired-view"
    >
      <q-card-section class="text-center q-gutter-md">
        <div class="text-h4">
          {{ $t("password-reset.expired.title") }}
        </div>
        <div>
          {{ $t("password-reset.expired.get-new") }}
          <a href="#" @click="state = 'request'">
            {{ $t("password-reset.expired.action") }}
          </a>
        </div>
      </q-card-section>
    </q-card>
    <!-- Reset password -->
    <div v-else-if="state === 'reset' || state === 'resetting'">
      <q-form ref="formRef" greedy class="reset-view">
        <q-card style="padding: 100px 150px">
          <q-card-section class="text-h4 text-center">
            {{ $t("password-reset.reset.title") }}
          </q-card-section>
          <q-card-section class="q-gutter-md">
            <PasswordValidation
              class="password"
              @update="(p: string | undefined) => (password = p)"
            />
            <q-input
              v-model="repeatedPassword"
              :label="$t('password-reset.reset.repeat')"
              type="password"
              outlined
              :rules="[
                (val) =>
                  (val && val === password) ||
                  $t('password-reset.reset.mismatch'),
              ]"
              lazy-rules
              class="repeated-password"
            />
          </q-card-section>
          <q-card-actions align="right" class="q-pr-md">
            <q-btn
              :label="$t('password-reset.reset.reset-button')"
              :loading="state === 'resetting'"
              class="reset-button"
              color="primary"
              @click="reset"
            />
          </q-card-actions>
        </q-card>
      </q-form>
    </div>
    <!-- reset was done -->
    <q-card
      v-else-if="state === 'done'"
      style="padding: 20px"
      class="done-view"
    >
      <q-card-section class="text-center q-gutter-md">
        <div class="text-h6">
          {{ $t("password-reset.done.title") }}
        </div>
        <q-card-section class="text-center">
          <q-icon name="mdi-check-circle" size="150px" color="positive" />
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            :label="$t('password-reset.done.login-button')"
            class="login-button"
            color="primary"
            :to="{ name: 'login' }"
          />
        </q-card-actions>
      </q-card-section>
    </q-card>
  </GuestLayout>
</template>

<script setup lang="ts">
import { ref, inject, onMounted, computed } from "vue";
import { Routing } from "~/routing";
import { ApiAuthentication } from "~/api";
import { decodedValidSub } from "~/jwt";
import { QInput } from "quasar";
import { useRetryDialog } from "~/retryDialog";
import GuestLayout from "~/GuestLayout.vue";
import PasswordValidation from "~/components/PasswordValidation.vue";

const routing = inject(Routing.InjectionKey) as Routing;
const auth = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;
const { withRetryDialog } = useRetryDialog();

const formRef = ref();
const adminEmail = ref<string>(
  routing?.query?.email ? routing.query.email.toString() : "",
);
const password = ref<string | undefined>(undefined);
const repeatedPassword = ref("");

const resetToken = computed(() => routing?.query?.reset_token?.toString());

const state = ref<
  | "request"
  | "requesting"
  | "requested"
  | "reset"
  | "resetting"
  | "done"
  | "expired"
>(resetToken.value === undefined ? "request" : "reset");

async function request() {
  if (!(await formRef.value.validate())) return;

  state.value = "requesting";
  await withRetryDialog(() => auth.requestPasswordReset(adminEmail.value));
  state.value = "requested";
}

async function reset() {
  const newPassword = password.value;
  const token = resetToken.value;

  if (token === undefined) return;
  if (newPassword === undefined) return;
  if (!(await formRef.value.validate())) return;

  state.value = "resetting";
  const success = await withRetryDialog(() =>
    auth.passwordReset(adminEmail.value, newPassword, token),
  );
  state.value = success ? "done" : "expired";
}

onMounted(() => {
  if (!resetToken.value) return;
  const sub = decodedValidSub(resetToken.value);
  if (sub === undefined) {
    state.value = "expired";
  } else adminEmail.value = sub;
});
</script>
