<template>
  <div ref="el" v-touch-hold class="crop">
    {{ props.text }}
    <Tooltip v-if="showTooltip">
      {{ props.text }}
    </Tooltip>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeUpdate, onMounted, onUnmounted } from "vue";

const props = defineProps<{
  text: string;
}>();

const el = ref<HTMLDivElement>();
const showTooltip = ref(false);

function updateTooltipState() {
  showTooltip.value = el.value?.clientWidth != el.value?.scrollWidth;
}

onMounted(() => window.addEventListener("resize", updateTooltipState));
onUnmounted(() => window.removeEventListener("resize", updateTooltipState));

onMounted(updateTooltipState);
onBeforeUpdate(updateTooltipState);
</script>

<style>
.crop {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
