<template>
  <GuestLayout
    :on-premises="state === 'ready'"
    :login-redirect="state === 'ready'"
  >
    <q-card v-if="state === 'done'" style="padding: 20px" class="done-view">
      <q-card-section class="text-center q-gutter-md">
        <div class="text-h4">
          {{ $t("create-account.done.title") }}
        </div>
        <div>
          {{ $t("create-account.done.message") }}
          <p class="text-bold">
            {{ adminEmail }}
          </p>
        </div>
        <div>{{ $t("create-account.done.close") }}</div>
      </q-card-section>
      <q-card-section class="text-center">
        <q-icon name="mdi-email-check" size="150px" color="primary" />
      </q-card-section>
    </q-card>
    <q-form v-else ref="formRef" greedy class="form-view">
      <q-card style="padding: 50px 150px">
        <q-card-section class="text-h4 text-center">
          {{ $t("create-account.title") }}
        </q-card-section>
        <q-card-section class="q-gutter-lg q-pa-sm">
          <q-input
            v-model="adminEmail"
            outlined
            type="email"
            :label="$t('create-account.email.title')"
            autofocus
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val, rules) => rules.email(val) || $t('input.enter-valid-email'),
            ]"
            lazy-rules
            class="admin-email"
            input-class="admin-email-input"
            :disable="adminEmailDisabled"
          />
          <PasswordValidation
            @update="(p: string | undefined) => (password = p)"
          />
          <div>
            <q-checkbox
              v-model="accepted"
              keep-color
              class="accept-terms"
              :color="!accepted && highlightAccepted ? 'negative' : 'primary'"
            />
            {{ $t("create-account.accept.prefix") }}
            <a target="_blank" :href="t('links.terms-of-use.url')">
              {{ $t("create-account.accept.terms-conditions") }}
            </a>
            {{ $t("create-account.accept.and") }}
            <a target="_blank" :href="t('links.privacy-policy.url')">
              {{ $t("create-account.accept.privacy-policy") }}
            </a>
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            :label="$t('create-account.create-button')"
            :loading="state === 'loading'"
            class="request-button"
            color="primary"
            @click="requestAccountCreation"
          />
        </q-card-actions>
      </q-card>
    </q-form>
  </GuestLayout>
</template>

<script setup lang="ts">
import { ref, inject, computed, onMounted } from "vue";
import { QInput } from "quasar";
import GuestLayout from "~/GuestLayout.vue";
import { useI18n } from "vue-i18n";
import { ApiAuthentication } from "~/api";
import { Routing } from "~/routing";
import { useRetryDialog } from "~/retryDialog";
import { decodedValidSub } from "~/jwt";
import PasswordValidation from "~/components/PasswordValidation.vue";

const { t } = useI18n();
const api = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;
const routing = inject(Routing.InjectionKey) as Routing;
const { withRetryDialog } = useRetryDialog();

const formRef = ref();

const adminEmail = ref("");
const password = ref<string | undefined>(undefined);
const accepted = ref(false);
const highlightAccepted = ref(false);
const adminEmailDisabled = ref(false);

const state = ref<"ready" | "loading" | "done">("ready");

const activationToken = computed(() =>
  routing?.query?.activation_token?.toString(),
);

async function requestAccountCreation() {
  highlightAccepted.value = true;
  const currentPassword = password.value;
  if (
    !(
      (await formRef.value.validate()) &&
      currentPassword !== undefined &&
      accepted.value
    )
  )
    return;

  if (activationToken.value)
    await createAccount(currentPassword, activationToken.value);
  else await sendVerificationMail(currentPassword);
}

async function createAccount(validPassword: string, token: string) {
  state.value = "loading";
  await withRetryDialog(async () => {
    const created = await api.createAdmin(
      adminEmail.value,
      validPassword,
      token,
    );
    if (created) {
      await api.updateToken(adminEmail.value, validPassword);
      await routing.admin();
    } else await routing.unauthorized();
  });
}

async function sendVerificationMail(validPassword: string) {
  state.value = "loading";
  await withRetryDialog(() =>
    api.requestVerificationMail(adminEmail.value, validPassword),
  );
  state.value = "done";
}

onMounted(() => {
  if (activationToken.value) {
    const sub = decodedValidSub(activationToken.value, false);
    if (sub) {
      adminEmailDisabled.value = true;
      adminEmail.value = sub;
    }
  }
});
</script>

<style>
li.ok {
  list-style: "\2714\FE0F"; /* check mark emoji */
  /* workaround to set the color of the emoji */
  color: transparent;
  text-shadow: 0 0 0 darkgreen;
}

div.ok {
  color: darkgreen;
}

.q-checkbox__bg {
  height: 100%;
  width: 100%;
  position: unset;
}
</style>
