<template>
  <q-layout view="hHh lPr fFf">
    <q-header v-if="!!$slots.toolbar" class="bg-white text-black">
      <q-toolbar class="limited toolbar">
        <slot name="toolbar" />
      </q-toolbar>
    </q-header>
    <div class="limited">
      <q-drawer
        :model-value="true"
        side="left"
        behavior="desktop"
        :class="['column', !$slots.navbar ? 'transparent' : 'foreground']"
        :mini="quasar.screen.lt.lg && !showDrawer"
        :mini-to-overlay="quasar.screen.lt.lg && showDrawer"
        @mouseenter="showDrawer = true"
        @mouseleave="showDrawer = false"
        @click="showDrawer = false"
      >
        <slot name="navbar" />

        <q-item class="col-grow" />

        <q-item class="q-ma-lg column q-mini-drawer-hide">
          <div>
            v{{ version }}
            <span v-if="!release"
              >&nbsp;({{
                commit_hash ? commit_hash.slice(0, 8) : "none"
              }})</span
            >
          </div>
          <a
            :href="$t('links.documentation.overview.url')"
            target="_blank"
            rel="noopener help"
          >
            {{ $t("links.documentation.overview.text") }}
          </a>
          <a
            :href="$t('links.changelog.url')"
            target="_blank"
            rel="noopener help"
          >
            {{ $t("links.changelog.text") }}
          </a>
          <a :href="$t('links.support.url')" target="_blank" rel="noopener me">
            {{ $t("links.support.text") }}
          </a>
          <q-separator size="2px" spaced />
          <a
            :href="$t('links.terms-of-use.url')"
            target="_blank"
            rel="noopener license"
          >
            {{ $t("links.terms-of-use.text") }}
          </a>
          <a
            :href="$t('links.privacy-policy.url')"
            target="_blank"
            rel="noopener"
          >
            {{ $t("links.privacy-policy.text") }}
          </a>
          <a
            :href="$t('links.legal-notice.url')"
            target="_blank"
            rel="noopener license"
          >
            {{ $t("links.legal-notice.text") }}
          </a>
        </q-item>
      </q-drawer>

      <q-page-container class="row flex-center">
        <q-page :style-fn="noScrolling" class="full-width background q-pa-xl">
          <slot />
        </q-page>
      </q-page-container>
    </div>
  </q-layout>
</template>

<script setup lang="ts">
import { useQuasar } from "quasar";
import { ref } from "vue";

const quasar = useQuasar();

const showDrawer = ref(false);

const version = window.__APP_CONFIG__.version;
const commit_hash = window.__APP_CONFIG__.commit_hash;
const release = window.__APP_CONFIG__.release;

// makes sure that the page does not scroll
// child components (e.g. User-Table should handle correct scrolling)
// https://quasar.dev/layout/page#style-fn
function noScrolling(offset: number) {
  const height = `calc(100vh - ${offset ? offset : 0}px`;
  return { height, "max-height": height };
}
</script>

<style lang="css">
body,
.background {
  background: #f0f0f0 !important;
}

.q-toolbar {
  padding: 0 16px;
}

.q-drawer,
.transparent {
  background: transparent;
}

.foreground {
  background: white;
}

.limited {
  max-width: 1800px;
}

.toolbar {
  height: 60px;
}
</style>
