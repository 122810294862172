<template>
  <ContentList>
    <q-item class="q-pa-none">
      <q-item-section>
        <q-item-label class="text-h2">
          {{ $t("navigation.admins") }}
        </q-item-label>
        <q-item class="q-px-none q-py-md">
          <div class="text-xs">
            {{ $t("admins.hint") }}
          </div>
        </q-item>
        <q-item class="q-pa-none">
          <q-list class="admins full-width">
            <q-item
              v-for="admin in admins"
              :key="admin.email"
              class="q-pa-none admin"
            >
              <q-item-section>
                <q-item-label class="row items-center">
                  <a :href="`mailto:${admin.email}`">{{ admin.email }}</a>
                  <div v-if="admin.official" class="text-grey official">
                    &nbsp;{{ $t("admins.official-contact.label") }}
                    <HelpIcon
                      :tooltip="$t('admins.official-contact.tooltip')"
                    />
                  </div>
                </q-item-label>
              </q-item-section>
              <q-item-section>
                <q-item class="row items-center">
                  <q-btn-dropdown
                    class="project-admin-menu"
                    dropdown-icon="mdi-dots-vertical"
                    color="grey"
                    flat
                    dense
                    menu-anchor="top right"
                    menu-self="top left"
                  >
                    <q-list>
                      <q-item
                        v-close-popup
                        class="q-pa-none remove-admin"
                        style="min-height: 0"
                        clickable
                        :disable="admin.self || admin.official"
                        @click="removeAdmin(admin.email)"
                      >
                        <q-chip
                          icon="mdi-close"
                          :text-color="
                            admin.self || admin.official ? 'grey' : 'negative'
                          "
                          color="transparent"
                        >
                          {{ $t("admins.remove.label") }}
                        </q-chip>

                        <Tooltip v-if="admin.self">
                          {{ $t("admins.remove.tooltip-self") }}
                        </Tooltip>
                        <Tooltip v-else-if="admin.official">
                          {{ $t("admins.remove.tooltip-official-contact") }}
                        </Tooltip>
                      </q-item>

                      <q-item
                        v-close-popup
                        class="q-pa-none change-official-contact"
                        style="min-height: 0"
                        clickable
                        :disable="admin.official || !admin.active"
                        @click="makeOfficialContact(admin.email)"
                      >
                        <q-chip
                          icon="mdi-account-tie-voice"
                          :text-color="
                            admin.official || !admin.active ? 'grey' : 'black'
                          "
                          color="transparent"
                        >
                          {{ $t("admins.change.label") }}
                        </q-chip>

                        <Tooltip v-if="admin.official">
                          {{ $t("admins.change.tooltip-official-contact") }}
                        </Tooltip>
                        <Tooltip v-else-if="!admin.active">
                          {{ $t("admins.change.tooltip-inactive") }}
                        </Tooltip>
                        <Tooltip v-else>
                          {{ $t("admins.change.tooltip") }}
                        </Tooltip>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                </q-item>
              </q-item-section>
            </q-item>
            <q-item class="q-pa-none q-pt-md">
              <q-item-section>
                <q-item-label>
                  <q-input
                    ref="newAdminRef"
                    v-model="adminEmail"
                    outlined
                    type="email"
                    :label="$t('login.admin-email')"
                    :rules="[
                      (val) => (val && val.length > 0) || $t('input.required'),
                      (val, rules) =>
                        rules.email(val) || $t('input.enter-valid-email'),
                      (val) =>
                        !admins
                          .map((a) => a.email.toLowerCase())
                          .includes(val.toLowerCase()) ||
                        $t('admins.error.exists'),
                    ]"
                    lazy-rules="ondemand"
                    class="new-admin"
                    hide-bottom-space
                    :autofocus="false"
                  />
                </q-item-label>
              </q-item-section>
              <q-item-section style="justify-content: start">
                <q-item class="row items-center">
                  <q-btn class="add-admin" color="primary" @click="addAdmin">
                    {{ $t("admins.add.button.label") }}
                  </q-btn>
                </q-item>
              </q-item-section>
            </q-item>
          </q-list>
        </q-item>
      </q-item-section>
    </q-item>
  </ContentList>
</template>

<script setup lang="ts">
import { ref, inject, computed, type ComputedRef } from "vue";
import { ReactiveProjectState } from "~/ReactiveProjectState";
import { type ProjectAdmin } from "~/api";
import { QInput } from "quasar";
import { useRetryDialog } from "~/retryDialog";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";
import Tooltip from "~/components/Tooltip.vue";

const projectState = inject(
  ReactiveProjectState.InjectionKey,
) as ReactiveProjectState;
const { withRetryDialog } = useRetryDialog();
const quasar = useQuasar();
const { t } = useI18n();

const admins: ComputedRef<ProjectAdmin[]> = computed(() =>
  (projectState.admins.value || []).toSorted(
    (a: ProjectAdmin, b: ProjectAdmin) => {
      if (a.self) return -1;
      if (b.self) return 1;
      if (a.official) return -1;
      if (b.official) return 1;
      return 0;
    },
  ),
);
const adminEmail = ref("");
const newAdminRef = ref();

async function addAdmin() {
  if (!newAdminRef.value.validate()) return;
  await withRetryDialog(() => projectState.addAdmin(adminEmail.value));
  quasar.notify({
    type: "positive",
    classes: "notify-add-admin-success",
    message: `${adminEmail.value}`,
    caption: t("admins.add.notify.message"),
  });
  adminEmail.value = "";
}

function removeAdmin(adminEmail: string) {
  quasar
    .dialog({
      title: t("admins.remove.dialog.title", {
        email: adminEmail,
      }),
      class: "remove-admin-dialog",
      ok: {
        label: t("admins.remove.dialog.ok-button"),
        class: "ok-button",
      },
      cancel: {
        label: t("admins.remove.dialog.cancel-button"),
        class: "cancel-button",
        flat: true,
      },
    })
    .onOk(() => {
      withRetryDialog(() => projectState.removeAdmin(adminEmail));
      quasar.notify({
        type: "positive",
        classes: "notify-remove-admin-success",
        message: adminEmail,
        caption: t("admins.remove.notify.message"),
      });
    });
}

function makeOfficialContact(adminEmail: string) {
  quasar
    .dialog({
      title: t("admins.change.dialog.title", {
        email: adminEmail,
      }),
      message: `${t("admins.change.dialog.caption-assign")}
                <br /><br />
                ${t("admins.change.dialog.caption-revoke", {
                  email: admins.value
                    .filter((a) => a.official)
                    .map((a) => a.email)[0],
                })}`,
      html: true,
      class: "change-official-contact-dialog",
      ok: {
        label: t("admins.change.dialog.ok-button"),
        class: "ok-button",
      },
      cancel: {
        label: t("admins.change.dialog.cancel-button"),
        class: "cancel-button",
        flat: true,
      },
    })
    .onOk(() => {
      withRetryDialog(() => projectState.makeOfficialContact(adminEmail)).then(
        (success) =>
          quasar.notify({
            type: success ? "positive" : "negative",
            classes: success
              ? "notify-change-official-contact-success"
              : "notify-change-official-contact-failure",
            message: adminEmail,
            caption: success
              ? t("admins.change.notify.ok")
              : t("admins.change.notify.failed"),
          }),
      );
    });
}
</script>
