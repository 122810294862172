<template>
  <ContentList :loading="loading_settings">
    <q-item class="q-pa-none">
      <q-item-section class="text-h2">
        {{ $t("navigation.settings") }}
      </q-item-section>
    </q-item>

    <q-item class="q-pt-lg q-pb-xl q-px-none">
      <q-item-section>
        <q-item-label class="text-h5">
          {{ $t("settings.key.label") }}
        </q-item-label>
        <q-item-label caption>
          {{ $t("settings.key.hint") }}
          <a :href="$t('links.documentation.config.url')" target="_blank">
            {{ $t("settings.key.hint-link-text") }}
          </a>
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <EncryptionKey :value="credentialKey" />
      </q-item-section>
    </q-item>

    <div v-if="projectState.settings.value?.selfservice">
      <q-separator size="2px" />
      <div class="q-py-xl">
        <q-item class="q-px-none">
          <q-item-section>
            <q-item-label class="text-h5">
              {{ $t("settings.selfservice.label") }}
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item class="q-pa-none">
          <div class="col">
            <div class="text-bold">
              {{ $t("settings.selfservice.qr-code.title") }}
            </div>
            <div class="text-caption text-grey">
              {{ $t("settings.selfservice.qr-code.hint") }}
              <a :href="$t('links.selfservice.url')" target="_blank">{{
                $t("settings.selfservice.qr-code.learn")
              }}</a>
            </div>
            <QRCode
              ref="qrcode"
              :value="projectState.settings.value?.selfservice.url || ''"
              :size="128"
              :border="0"
              class="q-py-md"
            />
            <q-btn
              style="width: 128px"
              class="download-selfservice-qrcode"
              @click="qrcode.download(projectState.name)"
            >
              {{ $t("settings.selfservice.download") }}
            </q-btn>
            <div class="text-bold q-pt-md">
              {{ $t("settings.selfservice.registered-ids.title") }}
            </div>
            <div class="text-grey">
              {{
                projectState.settings.value?.selfservice?.usedCredentials
                  ? projectState.settings.value.selfservice
                      .usedCredentials[0] ==
                    projectState.settings.value.selfservice.usedCredentials[1]
                    ? $t("settings.selfservice.registered-ids.single-id", {
                        id: projectState.settings.value.selfservice
                          .usedCredentials[0],
                      })
                    : $t("settings.selfservice.registered-ids.multiple-ids", {
                        start:
                          projectState.settings.value?.selfservice
                            .usedCredentials[0],
                        end: projectState.settings.value?.selfservice
                          .usedCredentials[1],
                      })
                  : $t("settings.selfservice.registered-ids.no-ids")
              }}
            </div>
          </div>
        </q-item>
      </div>
    </div>

    <q-separator size="2px" />
    <q-item class="mail-settings-section q-py-xl q-px-none">
      <q-item-section>
        <q-item-label class="text-h5">
          {{ $t("settings.mail-settings.label") }}
        </q-item-label>
        <q-item-label caption class="q-pb-md">
          {{ $t("settings.mail-settings.hint") }}
        </q-item-label>
        <q-item-label>
          <MailSettingsEditor
            :settings="projectState.settings.value?.mailSettings || undefined"
            @send="sendTestMail"
            @update="updateMailSettings"
          />
        </q-item-label>
      </q-item-section>
    </q-item>

    <div
      v-if="!projectState.settings.value?.selfservice"
      class="auto-sync-section"
    >
      <q-separator size="2px" />
      <div class="q-py-xl">
        <q-item class="q-px-none">
          <q-item-section>
            <q-item-label class="text-h5">
              {{ $t("settings.autosync.label") }}
            </q-item-label>
            <q-item-label caption>
              {{ $t("settings.autosync.setup.text") }}
              <a :href="$t('links.documentation.autosync.url')" target="_blank">
                {{ $t("settings.autosync.setup.link.text") }}
              </a>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-item class="q-px-none q-py-none">
          <q-item-section>
            <q-item-label class="text-bold">
              {{ $t("settings.autosync.enable.label") }}
              <HelpIcon :tooltip="$t('settings.autosync.enable.tooltip')" />
            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-toggle
              class="autosync-control"
              :model-value="autosyncStatus.enabled"
              @update:model-value="(value) => updateAutosyncStatus(!!value)"
            />
          </q-item-section>
        </q-item>
        <q-item-label
          v-if="autosyncStatus.enabled"
          class="autosync-section q-pa-none"
        >
          <q-btn
            :label="$t('settings.autosync.task.download.text')"
            color="primary"
            class="config-task-download-button"
            @click="downloadAutoSyncTaskConfig"
          />

          <div v-if="autosyncStatus.last_autosync" class="autosync-status">
            <div class="info">
              {{
                $t("settings.autosync.status", {
                  date: formatDate(autosyncStatus.last_autosync),
                })
              }}
            </div>
            <div class="text-grey q-pl-sm q-pt-sm q-pb-md">
              {{ $t("settings.autosync.stop.text.pre") }}
              <a
                :href="$t('links.documentation.autosync.stop.url')"
                target="_blank"
              >
                {{ $t("settings.autosync.stop.text.anchor") }}
              </a>
              {{ $t("settings.autosync.stop.text.post") }}
            </div>
          </div>

          <div class="warning">
            {{ $t("settings.autosync.warning") }}
          </div>
        </q-item-label>
      </div>
    </div>

    <q-separator size="2px" />
    <q-item class="q-py-xl q-px-none">
      <q-item-section>
        <q-item-label class="text-h5">
          {{ $t("settings.support.label") }}
        </q-item-label>
        <q-item-label class="text-bold">
          {{ $t("settings.support.toggle.label") }}
        </q-item-label>
        <q-item-label caption class="line-wrap">
          {{ $t("settings.support.hint.text") }}
          <a :href="$t('links.statistics-policy.url')" target="_blank">
            {{ $t("settings.support.hint.learn-more.label") }}
          </a>
        </q-item-label>
      </q-item-section>
      <q-item-section avatar>
        <q-toggle
          :model-value="autoForwardSupportRequests"
          data-test="auto-forward-support-requests"
          @click="
            projectState.updateSettings({
              autoForwardSupportRequests: !autoForwardSupportRequests,
            })
          "
        />
      </q-item-section>
    </q-item>

    <div v-if="projectState.settings.value?.licenseStatus.canUpgrade">
      <q-separator size="2px" />
      <q-item class="license-upgrade q-py-xl q-px-none">
        <q-item-section>
          <q-item-label class="text-h5">
            {{ $t("settings.upgrade.label") }}
          </q-item-label>
          <q-item-label caption>
            <a
              href="#"
              @click="
                quasar.dialog({
                  component: UpgradeDialog,
                  componentProps: {
                    url: projectState.settings.value?.licenseStatus.projectUrl,
                  },
                })
              "
            >
              {{ $t("upgrade-dialog.label") }}
            </a>
            {{ $t("settings.upgrade.hint") }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </div>

    <q-separator size="2px" />
    <q-item class="delete-project-section q-pt-xl q-pb-md q-px-none">
      <q-item-section>
        <q-item-label class="text-h5">
          {{ $t("settings.delete.label") }}
        </q-item-label>
        <q-item-label>
          <a id="delete-project" href="#" @click="deleteProject()">
            {{ $t("settings.delete.pre") }}</a
          >
          {{ $t("settings.delete.post") }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </ContentList>
</template>

<script setup lang="ts">
import { ref, inject, computed, onMounted } from "vue";
import { ReactiveProjectState } from "~/ReactiveProjectState";
import { useQuasar, exportFile } from "quasar";
import UpgradeDialog from "~/UpgradeDialog.vue";
import QRCode from "~/components/QRCode.vue";
import HelpIcon from "~/components/HelpIcon.vue";
import { useI18n } from "vue-i18n";
import { useRetryDialog } from "~/retryDialog";
import { Routing } from "~/routing";
import ProjectDeletionDialog from "~/ProjectDeletionDialog.vue";
import MailSettingsEditor from "~/components/MailSettingsEditor.vue";
import EncryptionKey from "~/components/EncryptionKey.vue";
import { type MailSettings } from "~/api";

const quasar = useQuasar();
const { t } = useI18n();
const { withRetryDialog } = useRetryDialog();
const projectState = inject(
  ReactiveProjectState.InjectionKey,
) as ReactiveProjectState;
const routing = inject(Routing.InjectionKey) as Routing;

const loading_settings = computed(
  () => projectState.settings.value === undefined,
);
const autoForwardSupportRequests = computed(
  () => projectState.settings.value?.autoForwardSupportRequests || false,
);
const autosyncStatus = computed(() => ({
  enabled: projectState.settings.value?.userManagementMode == "autosync",
  last_autosync: projectState.settings.value?.lastAutoSync,
}));
const credentialKey = computed(() => {
  return projectState.settings.value?.credentialKey || "";
});

const qrcode = ref();

onMounted(() => {
  const highlight = routing.query?.highlight;
  if (!highlight) return;

  Array.from(document.getElementsByClassName(highlight.toString())).forEach(
    (el) => {
      el.scrollIntoView({ block: "nearest" });
      el.animate(
        {
          backgroundColor: [
            (el as HTMLElement).style.backgroundColor || "inherit",
            "#d0ecf5",
            (el as HTMLElement).style.backgroundColor || "inherit",
          ],
          opacity: "0.5",
        },
        { duration: 1000 },
      );
    },
  );
});

async function updateAutosyncStatus(enable: boolean) {
  if (!enable && projectState.settings.value?.lastAutoSync) {
    const cont = await new Promise<boolean>((resolve) => {
      quasar
        .dialog({
          title: t(`settings.autosync.disable.title`),
          message: t(`settings.autosync.disable.message`),
          class: "disable-autosync-dialog",
          ok: {
            label: t(`settings.autosync.disable.ok`),
            class: "ok-button",
          },
          cancel: {
            label: t(`settings.autosync.disable.cancel`),
            class: "cancel-button",
            flat: true,
          },
        })
        .onOk(() => resolve(true))
        .onCancel(() => resolve(false));
    });
    if (!cont) return;
  }
  await withRetryDialog(() =>
    projectState.updateSettings({ autosync: enable }),
  );
}

async function downloadAutoSyncTaskConfig() {
  quasar
    .dialog({
      title: t(`settings.autosync.task.download.dialog.title`),
      message: t(`settings.autosync.task.download.dialog.message`),
      class: "download-task-config-dialog",
      ok: {
        label: t(`settings.autosync.task.download.dialog.ok`),
        class: "ok-button",
      },
      cancel: {
        label: t(`settings.autosync.task.download.dialog.cancel`),
        class: "cancel-button",
        flat: true,
      },
    })
    .onOk(() => {
      projectState.autosyncConfig("task").then((file) => {
        exportFile(file.name, file);
      });
    });
}

async function deleteProject() {
  quasar
    .dialog({
      component: ProjectDeletionDialog,
      componentProps: {
        projectName: projectState.name,
      },
    })
    .onOk(() =>
      withRetryDialog(async () => {
        await projectState.delete();
        await routing.admin();
      }),
    );
}

async function sendTestMail(opts: {
  receiver: string;
  settings: MailSettings;
}) {
  withRetryDialog(async () => {
    await projectState.sendTestMail(opts.receiver, opts.settings);
  });
}

async function updateMailSettings(mailSettings: MailSettings | null) {
  withRetryDialog(async () => {
    await projectState.updateSettings({ mailSettings });
  });
}

function formatDate(dateTime: Date): string {
  const date = `${dateTime.getFullYear()}-${String(dateTime.getMonth() + 1).padStart(2, "0")}-${String(dateTime.getDate()).padStart(2, "0")}`;
  const time = `${String(dateTime.getHours()).padStart(2, "0")}:${String(dateTime.getMinutes()).padStart(2, "0")}:${String(dateTime.getSeconds()).padStart(2, "0")}`;
  const tz = dateTime
    .toLocaleDateString("en-US", {
      timeZoneName: "short",
    })
    .split(",")[1]!
    .trim();
  return `${date} at ${time} [${tz}]`;
}
</script>

<style>
.config-task-download-button {
  margin: 0 0 16px 0;
}
</style>
