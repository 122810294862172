<template>
  <div class="fit">
    <q-inner-loading :showing="props.loading" data-test="loading-indicator" />
    <q-list v-if="!props.loading" class="container">
      <div class="full-width bg-white scroll-area shadow-1 rounded-borders">
        <div class="content">
          <slot />
        </div>
      </div>
    </q-list>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ loading?: boolean }>(), {
  loading: false,
});
</script>
<style lang="scss">
.container {
  display: contents;
}

.scroll-area {
  overflow-y: scroll;
  max-height: 100%;
  padding: 30px;
}

.content {
  max-width: 1000px;
}
</style>
