<template>
  <AdminLayout>
    <router-view />

    <template #navbar>
      <q-list padding class="nav-bar q-pa-none">
        <q-separator />

        <div v-for="menuItem in menuItems" :key="menuItem.to">
          <q-item
            clickable
            :to="{ name: menuItem.to, params: { project: project } }"
            :class="['nav-item', menuItem.class]"
          >
            <q-item-section avatar>
              <q-icon :name="menuItem.icon" />
            </q-item-section>
            <q-item-section label>
              <q-item-label>{{ $t(menuItem.label) }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator />
        </div>
      </q-list>
    </template>

    <template #project-selection>
      <div v-if="routing.isProjectRoute.value" class="text-h4 text-primary">
        {{ $t("admin.project-label") }}
      </div>
      <q-btn-dropdown
        v-if="routing.isProjectRoute.value"
        class="col project-select q-pl-sm"
        text-color="primary"
        align="left"
        padding="none"
        menu-anchor="bottom left"
        menu-self="top left"
        auto-close
        flat
        no-caps
        :ripple="false"
        dropdown-icon="mdi-menu-down"
        no-icon-animation
        split
      >
        <template #label>
          <div id="current-project" class="text-h4">
            {{ project }}
          </div>
        </template>

        <template #default>
          <q-list>
            <q-item
              v-for="p in (projects || []).filter((p) => p != project)"
              :key="p"
              clickable
              :to="routing.sameOrDefaultRouteWithProject(p)"
            >
              <q-item-label
                class="text-primary text-h4 text-center alt-project"
              >
                {{ p }}
              </q-item-label>
            </q-item>
            <q-separator
              v-if="(projects || []).filter((p) => p != project).length > 0"
              size="2px"
              inset
            />
            <q-item key="__create__" clickable :to="{ name: 'create-project' }">
              <q-item-label
                class="text-primary text-h4 text-center create-project"
              >
                {{ $t("admin.create-project") }}
              </q-item-label>
            </q-item>
          </q-list>
        </template>
      </q-btn-dropdown>
    </template>
  </AdminLayout>
</template>

<script setup lang="ts">
import {
  provide,
  inject,
  onMounted,
  onUnmounted,
  ref,
  computed,
  watchEffect,
} from "vue";
import { ProjectApi, ApiAuthentication } from "~/api";
import { ApiError } from "~/errors";
import { ReactiveProjectState } from "~/ReactiveProjectState";
import { useRetryDialog } from "~/retryDialog";
import AdminLayout from "~/AdminLayout.vue";
import { Routing } from "~/routing";

async function apiCallHandler<T>(fn: () => Promise<T>): Promise<T | undefined> {
  try {
    return await withRetryDialog(() => fn());
  } catch (e) {
    if (e instanceof ApiError && e.handled) return undefined;
    else throw e;
  }
}

const { withRetryDialog } = useRetryDialog();

const routing = inject(Routing.InjectionKey) as Routing;
const auth = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;

const projects = ref(undefined as string[] | undefined);
const project = computed(() => routing.projectName.value);

const projectApi = inject(
  ProjectApi.InjectionKey,
  new ProjectApi(project.value || "", auth),
);
projectApi.onconnectionlost = () =>
  withRetryDialog(
    () =>
      new Promise((r) => {
        projectApi.startWatching();
        r(undefined);
      }),
  );

const projectState = inject(
  ReactiveProjectState.InjectionKey,
  new ReactiveProjectState(projectApi, apiCallHandler),
);
provide(ReactiveProjectState.InjectionKey, projectState);

const isSelfService = computed(
  () => projectState.settings.value?.userManagementMode === "selfservice",
);
const usersEnabled = computed(() => !isSelfService.value);

const usersMenuItem = {
  to: "users",
  icon: "mdi-account-multiple",
  label: "navigation.users",
  class: "users",
};
const adminsMenuItem = {
  to: "project-admins",
  icon: "mid-admins",
  label: "navigation.admins",
  class: "project-admins",
};
const settingsMenuItem = {
  to: "settings",
  icon: "mdi-cog",
  label: "navigation.settings",
  class: "settings",
};
const menuItems = computed(() => {
  if (isSelfService.value) return [settingsMenuItem, adminsMenuItem];
  else return [usersMenuItem, adminsMenuItem, settingsMenuItem];
});

onMounted(() => {
  projectApi.startWatching();
  auth.projects().then((p) => {
    projects.value = p.sort();
  });
});
onUnmounted(() => projectApi.stopWatching());

watchEffect(() => {
  let redirect = undefined;
  if (routing.currentRouteName.value === "users" && !usersEnabled.value)
    redirect = "settings";
  else if (routing.currentRouteName.value === "project")
    redirect = usersEnabled.value ? "users" : "settings";

  if (redirect === "users") routing.users(project.value);
  else if (redirect === "settings")
    routing.settings({ project: project.value });
});
</script>
