<template>
  <AdminLayout>
    <q-form
      v-if="view == 'main'"
      ref="formRef"
      greedy
      class="fixed-center-top"
      style="min-width: 500px; max-width: 800px; width: 40%"
    >
      <q-card class="q-pa-xl">
        <q-card-section class="text-h4 text-center">
          {{ $t("create-project.title.main") }}
        </q-card-section>
        <q-card-section>
          <q-input
            v-model="projectName"
            outlined
            :label="$t('create-project.project-name.title')"
            autofocus
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val) =>
                val.length <= 60 ||
                $t('input.character-limit', { value: '60' }),
              (val) =>
                !invalidProjectNames.includes(val) ||
                $t('create-project.error'),
            ]"
            class="project-name"
          >
            <template #after>
              <q-icon size="xs" name="mdi-help-circle-outline">
                <Tooltip>
                  {{ $t("create-project.project-name.tooltip") }}
                </Tooltip>
              </q-icon>
            </template>
          </q-input>
        </q-card-section>
        <q-card-section class="q-pr-none">
          <q-list>
            <div class="col text-bold q-pb-sm">
              {{ $t("create-project.mode.label") }}
            </div>

            <q-item tag="label" class="q-pa-none mode-by-admin">
              <q-item-section avatar>
                <q-radio v-model="mode" val="admin" />
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  {{ $t("create-project.mode.admin") }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item tag="label" class="q-pa-none mode-via-selfservice">
              <q-item-section avatar>
                <q-radio v-model="mode" val="selfservice" />
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  {{ $t("create-project.mode.selfservice") }}
                  <a
                    :href="$t('links.selfservice.url')"
                    target="_blank"
                    style="white-space: nowrap"
                  >
                    {{ $t("create-project.mode.learn") }}
                  </a>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-section>
          <div class="warning text-center">
            {{ $t("create-project.warning") }}
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            :label="$t('create-project.cancel-button')"
            class="cancel-button"
            flat
            color="primary"
            @click="routing.admin()"
          />
          <q-btn
            :label="$t('create-project.create-button')"
            class="create-button"
            color="primary"
            :loading="inProgress"
            @click="createProject()"
          />
        </q-card-actions>
      </q-card>
    </q-form>

    <q-card
      v-if="view == 'mail'"
      class="fixed-center-top q-pa-xl"
      style="min-width: 500px; max-width: 800px; width: 40%"
    >
      <q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label class="text-h4 text-center">
              {{ $t("create-project.title.mail") }}
            </q-item-label>
            <q-item-label caption class="text-center q-pt-sm">
              {{ $t("create-project.title-hint.mail") }}
            </q-item-label>
            <q-item-label class="q-pt-lg q-pb-sm">
              <div class="column items-center">
                <MailSettingsEditor
                  :settings="mailSettings || undefined"
                  @update="
                    (updated) => {
                      mailSettings = updated;
                      updateMailSettings();
                    }
                  "
                  @send="sendTestMail"
                />
              </div>
            </q-item-label>
            <q-item-label caption class="text-center">
              {{ $t("create-project.hint") }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          :label="$t('create-project.continue-button')"
          class="continue-button"
          color="primary"
          @click="() => routing.project(projectName)"
        />
      </q-card-actions>
    </q-card>
  </AdminLayout>
</template>

<script setup lang="ts">
import { QInput } from "quasar";
import { ref, inject, reactive } from "vue";
import { ApiAuthentication, type MailSettings, ProjectApi } from "~/api";
import { Routing } from "~/routing";
import { useRetryDialog } from "~/retryDialog";
import AdminLayout from "~/AdminLayout.vue";
import MailSettingsEditor from "~/components/MailSettingsEditor.vue";

const api = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;
const routing = inject(Routing.InjectionKey) as Routing;

const { withRetryDialog } = useRetryDialog();

const view = ref<"main" | "mail">("main");

const invalidProjectNames = reactive<string[]>([]);
const inProgress = ref(false);
const formRef = ref();
const projectName = ref("");
const mode = ref<"admin" | "selfservice">("admin");
const mailSettings = ref<MailSettings | null>(null);

async function createProject() {
  const success = await formRef.value.validate();
  if (success) {
    inProgress.value = true;
    try {
      const created = await withRetryDialog(() =>
        api.createProject({
          projectName: projectName.value,
          autoForwardSupportRequest: true,
          selfservice: mode.value == "selfservice",
          mailSettings: null,
        }),
      );
      if (created) view.value = "mail";
      else {
        invalidProjectNames.push(projectName.value);
        formRef.value.validate();
      }
    } finally {
      inProgress.value = false;
    }
  }
}

async function updateMailSettings() {
  const projectApi = new ProjectApi(projectName.value, api);
  await withRetryDialog(async () =>
    projectApi.updateSettings({ mailSettings: mailSettings.value }),
  );
}

async function sendTestMail(opts: {
  receiver: string;
  settings: MailSettings;
}) {
  await withRetryDialog(async () => {
    await api.sendTestMail(opts.receiver, opts.settings, projectName.value);
  });
}
</script>
