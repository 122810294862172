<template>
  <div class="row">
    <q-input
      outlined
      readonly
      :label="props.label"
      :autofocus="false"
      :input-class="visibility ? 'text' : 'undisclosed'"
      :model-value="hexValue"
      data-test="mobileid-key"
      style="width: 370px"
    />
    <q-btn
      flat
      dense
      round
      :icon="visibility ? 'mdi-eye-off' : 'mdi-eye'"
      class="toggle-mobileid-key-visibility"
      @click="changecredentialKeyVisibility()"
    >
      <Tooltip>{{ visibility ? "Hide" : "Show" }}</Tooltip>
    </q-btn>
    <ClipboardIcon :value="hexValue" class="copy-mobileid-key-to-clipboard" />
  </div>
</template>

<script setup lang="ts">
import ClipboardIcon from "~/components/ClipboardIcon.vue";
import { computed, ref } from "vue";

const props = withDefaults(
  defineProps<{
    value: string;
    label?: string;
    visiblityTimeout?: number;
  }>(),
  {
    visiblityTimeout: 5000,
    label: undefined,
  },
);

const visibility = ref(false);

const hexValue = computed(() => {
  return [...props.value]
    .map((e) => e.charCodeAt(0).toString(16).padStart(2, "0").toUpperCase())
    .join(" ");
});

function changecredentialKeyVisibility(value: boolean | undefined = undefined) {
  visibility.value = value === undefined ? !visibility.value : value;
  if (visibility.value)
    setTimeout(
      () => changecredentialKeyVisibility(false),
      props.visiblityTimeout,
    );
}
</script>
