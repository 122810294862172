<template>
  <q-dialog
    ref="dialogRef"
    :model-value="true"
    class="user-import-dialog"
    @hide="onDialogHide"
  >
    <q-card class="q-dialog-plugin">
      <q-card-section class="q-pa-sm">
        <div class="text-h2 q-pa-sm">
          {{ $t("users.import-dialog.title") }}
        </div>
        <div class="q-pa-sm">
          {{ $t("users.import-dialog.description") }}
        </div>
        <div class="text-bold q-pa-sm">
          {{ $t("users.import-dialog.field-order") }}
        </div>
        <div class="warning q-ma-sm">
          <div>{{ $t("users.import-dialog.warnings.message") }}</div>
          <ul class="q-pa-xs q-ma-none">
            <li
              v-for="(key, ndx) in $tm('users.import-dialog.warnings.items')"
              :key="ndx"
            >
              {{ key }}
            </li>
          </ul>
        </div>
        <q-uploader
          class="upload-area q-ma-sm q-mt-md"
          flat
          @added="onFilesAdded"
        >
          <template #header />
          <template #list>
            <q-uploader-add-trigger />
            <q-icon name="mdi-file" size="xl" color="grey" />
            <p
              v-if="selectedFile"
              class="q-pa-xs q-ma-none text-bold file-name"
            >
              {{ selectedFile.name }}
            </p>
            <p class="q-pa-none q-ma-none">
              {{ $t("users.import-dialog.upload-area.drag") }}
              <a>{{ $t("users.import-dialog.upload-area.browse") }}</a>
            </p>
          </template>
        </q-uploader>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          :label="$t('users.import-dialog.cancel-button')"
          color="primary"
          flat
          class="cancel-button"
          :disable="uploading"
          @click="onDialogCancel()"
        />
        <q-btn
          :label="$t('users.import-dialog.import-button')"
          color="primary"
          class="import-button"
          :loading="uploading"
          :disable="!selectedFile"
          @click="onImport"
        />
      </q-card-actions>
    </q-card>

    <!-- error dialog -->
    <q-dialog v-model="showErrorDialog" class="import-error-dialog">
      <q-card class="q-dialog-plugin">
        <q-card-section>
          <div class="text-h2 q-pa-sm">
            <q-icon name="mdi-close-circle" color="negative" size="xx-large" />
            {{ $t("users.import-dialog.failed.title") }}
          </div>
          <div class="q-pa-sm">
            {{ $t("users.import-dialog.failed.message") }}
          </div>
          <div class="error q-ma-sm">
            <ul class="q-pa-sm q-ma-none">
              <li v-for="(key, ndx) in importIssues" :key="ndx">
                <b v-if="key.location">{{ key.location }}: </b>{{ key.message }}
              </li>
            </ul>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            v-if="upgradeRequired"
            :label="$t('users.import-dialog.failed.upgrade-button')"
            color="primary"
            class="upgrade-button"
            @click="
              showErrorDialog = false;
              onDialogCancel();
              props.onUpgrade();
            "
          />
          <q-btn
            :label="$t('users.import-dialog.failed.ok-button')"
            color="primary"
            class="ok-button"
            @click="showErrorDialog = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-dialog>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useDialogPluginComponent, useQuasar } from "quasar";
import { ref } from "vue";
import { AutoSyncError, AutoSyncIssue } from "~/errors";
import { ReactiveProjectState } from "~/ReactiveProjectState";

defineEmits({ ...useDialogPluginComponent.emitsObject });

const props = defineProps<{
  projectState: ReactiveProjectState;
  onUpgrade: () => void;
}>();

const { t } = useI18n();
const quasar = useQuasar();
const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const selectedFile = ref<File | undefined>(undefined);
const uploading = ref<boolean>(false);
const showErrorDialog = ref<boolean>(false);
const importIssues = ref<AutoSyncIssue[]>([]);
const upgradeRequired = ref<boolean>(false);

function onFilesAdded(files: readonly File[]) {
  if (files.length == 1) selectedFile.value = files[0];
}

async function onImport() {
  if (!selectedFile.value) return;

  uploading.value = true;
  upgradeRequired.value = false;
  try {
    await props.projectState.autosync(selectedFile.value);
    quasar.notify({
      type: "positive",
      classes: "notify-import-completed",
      message: selectedFile.value.name,
      caption: t("users.import-dialog.completed"),
    });
    onDialogOK();
  } catch (e) {
    if (e instanceof AutoSyncError) {
      upgradeRequired.value = e.upgradeRequired;
      importIssues.value = e.issues;
    } else {
      importIssues.value = [
        new AutoSyncIssue(
          t("users.import-dialog.failed.server.location"),
          t("users.import-dialog.failed.server.message"),
        ),
      ];
    }
    showErrorDialog.value = true;
  } finally {
    uploading.value = false;
  }
}
</script>

<style lang="css">
.upload-area {
  border: 5px dashed black;
  border-radius: 20px;
  text-align: center;
  width: unset;
}
</style>
