<template>
  <q-input
    outlined
    :autofocus="props.autofocus"
    :label="props.label"
    :model-value="props.modelValue"
    :type="props.type"
    :rules="props.rules"
    lazy-rules
    :class="{ 'q-mb-md': true, highlighted: infos.length > 0 }"
    bottom-slots
    @update:model-value="(v) => emit('update:modelValue', v)"
  >
    <template #append>
      <q-icon
        v-if="warnings.length > 0"
        class="warn"
        name="mdi-alert"
        color="orange"
      >
        <Tooltip>
          <p v-for="(warning, i) in warnings" :key="i">
            {{ warning }}
          </p>
        </Tooltip>
      </q-icon>
      <q-icon
        v-if="infos.length > 0"
        class="information"
        name="mdi-information"
        color="primary"
      />
    </template>

    <template v-if="infos.length > 0" #hint>
      <div v-for="(info, i) in infos" :key="i" class="text-primary">
        {{ info }}
      </div>
    </template>
    <template v-else-if="hint" #hint>
      {{ hint }}
    </template>
  </q-input>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  type EmbeddedValidationRule,
  type EmbeddedValidationRuleFn,
} from "quasar";

type ModelValue = string;

const emit = defineEmits(["update:modelValue"]);

type Rule = (
  value: ModelValue,
  rules: Record<EmbeddedValidationRule, EmbeddedValidationRuleFn>,
) => boolean | string;
const props = withDefaults(
  defineProps<{
    modelValue: ModelValue;
    label: string;
    rules?: Rule[];
    warnings?: ((value: ModelValue) => boolean | string)[];
    infos?: ((value: ModelValue) => boolean | string)[];
    hint?: string;
    type?: "text" | "password" | "email";
    autofocus?: boolean;
  }>(),
  {
    rules: () => [],
    warnings: () => [],
    infos: () => [],
    hint: undefined,
    type: "text",
    autofocus: false,
  },
);

const warnings = computed(() =>
  props.warnings.map((fn) => fn(props.modelValue)).filter((v) => v !== true),
);
const infos = computed(() =>
  props.infos.map((fn) => fn(props.modelValue)).filter((v) => v !== false),
);
</script>

<style lang="scss">
.highlighted .q-field__control:before {
  border: $primary solid 2px !important;
}
</style>
