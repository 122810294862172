<template>
  <q-icon name="mdi-help-circle-outline" class="q-px-xs">
    <q-tooltip v-if="props.tooltip" max-width="400px">
      {{ props.tooltip }}
    </q-tooltip>
  </q-icon>
</template>

<script setup lang="ts">
const props = defineProps<{ tooltip?: string }>();
</script>
