<template>
  <router-view />
</template>

<script setup lang="ts">
import { provide } from "vue";
import { createRouting, Routing } from "~/routing";
import { ApiAuthentication } from "~/api";

const auth = new ApiAuthentication();
const routing = createRouting();

auth.onunauthorized = (e: Event) => {
  e.preventDefault();
  routing.unauthorized();
};
routing.authenticationGuard = () => {
  const info = auth.authenticationInfo;
  if (info === undefined) return "unauthenticated";
  if (info.expired) return "expired";
  return "authenticated";
};

provide(ApiAuthentication.InjectionKey, auth);
provide(Routing.InjectionKey, routing);
</script>
