import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { Quasar, Dialog, Notify, IconSet } from "quasar";
import { createI18n } from "vue-i18n";
import * as Sentry from "@sentry/vue";

import "quasar/src/css/index.sass";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/mdi-v7/mdi-v7.css";

import en from "./locales/en.yaml";
import en_baltech from "./locales/en-baltech.yaml";

import HelpIcon from "~/components/HelpIcon.vue";
import Tooltip from "~/components/Tooltip.vue";
import ContentList from "~/components/ContentList.vue";

import App from "~/App.vue";
import Users from "~/Users.vue";
import Settings from "~/Settings.vue";
import ProjectAdmins from "~/ProjectAdmins.vue";
import Login from "~/Login.vue";
import CreateProject from "~/CreateProject.vue";
import CreateAccount from "~/CreateAccount.vue";
import Project from "~/Project.vue";
import PasswordReset from "~/PasswordReset.vue";
import ProjectRedirect from "~/ProjectRedirect.vue";

import "~/main.scss";

const app = createApp(App);
const router = createRouter({
  routes: [
    {
      path: "/",
      redirect: { name: "project-redirect" },
    },
    {
      name: "login",
      path: "/login",
      component: Login,
    },
    {
      name: "password-reset",
      path: "/password-reset",
      component: PasswordReset,
    },
    {
      name: "create-account",
      path: "/create/account",
      component: CreateAccount,
    },
    {
      name: "create-project",
      path: "/create/project",
      component: CreateProject,
      meta: {
        admin: true,
      },
    },
    {
      name: "project-redirect",
      component: ProjectRedirect,
      path: "/projects",
      meta: {
        admin: true,
      },
    },
    {
      name: "project",
      component: Project,
      props: (route) => ({
        key: route.params.project,
      }),
      path: "/projects/:project",
      meta: {
        admin: true,
        project: true,
      },
      children: [
        {
          name: "users",
          path: "users",
          component: Users,
        },
        {
          name: "settings",
          path: "settings",
          component: Settings,
        },
        {
          name: "project-admins",
          path: "admins",
          component: ProjectAdmins,
        },
      ],
    },
    {
      path: "/:path(.*)",
      redirect: { name: "project-redirect", params: {} },
    },
  ],
  history: createWebHistory(),
});

const { sentry_dsn, full_version, environment, tracing, baltech_deployment } =
  window.__APP_CONFIG__;
if (sentry_dsn) {
  Sentry.init({
    app,
    dsn: sentry_dsn,
    release: full_version,
    environment: environment,
    enableTracing: tracing,
    trackComponents: tracing,
    integrations: tracing
      ? [
          Sentry.replayIntegration(),
          Sentry.browserProfilingIntegration(),
          Sentry.browserTracingIntegration({ router }),
        ]
      : [],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
  });
}

/*
custom svg icons must be supplied in this format
<path>(&&path)|viewbox
https://quasar.dev/vue-components/icon#svg-icon-format

`img:<filepath>` does not work because it won't allow to change colors
https://github.com/quasarframework/quasar/issues/6027#issuecomment-573112451
 */
const MIDIcons: Record<string, string> = {
  // like mdi-account-multiple but with ties like mdi-account-tie
  "mid-admins":
    "M 12.5,7.5 A 3.5,3.5 0 1 0 9,11 3.5,3.5 0 0 0 12.5,7.5&&m 12.5,13.405 c 0,0.795 -0.245,2.647499 -1.916252,4.717498 L 9.8749993,14.5 10.6975,13.09 C 10.155,13.0375 9.5862496,13 8.9999996,13 8.4137496,13 7.8449997,13.0375 7.3024992,13.09 L 8.1249996,14.5 7.4162492,18.122498 C 5.7449994,16.052499 5.4999995,14.2 5.4999995,13.405 3.4087498,13.93 1.9999997,14.875 1.9999997,16 v 3 H 16 v -3 c 0,-1.125 -1.4,-2.07 -3.5,-2.595 z&&m 15,4 a 3.39,3.39 0 0 0 -1.93,0.59 5,5 0 0 1 0,5.82 A 3.39,3.39 0 0 0 15,11 3.5,3.5 0 0 0 15,4 Z&&M 15.94,13 A 5.32,5.32 0 0 1 18,17 v 2 h 4 v -2 c 0,0 0,-3.63 -6.06,-4|0 0 24 24",
};

IconSet.iconMapFn = (iconName) => {
  const midIcon = MIDIcons[iconName];
  if (midIcon) {
    return {
      icon: midIcon,
    };
  }
};

app
  .use(Quasar, {
    plugins: { Dialog, Notify },
    config: { notify: { position: "top" } },
  })
  .use(
    createI18n({
      locale: baltech_deployment ? "en-baltech" : "en",
      messages: { "en-baltech": en_baltech, en },
      missingWarn: false,
      fallbackWarn: false,
    }),
  )
  .use(router)
  .component("HelpIcon", HelpIcon)
  .component("Tooltip", Tooltip)
  .component("ContentList", ContentList)
  .mount("#app");
