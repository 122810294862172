<template>
  <BaseLayout>
    <template #toolbar>
      <div class="fit row justify-start items-center q-pr-lg">
        <div class="col-auto row justify-content-center">
          <img :src="$t('app.logo')" class="full-height app-logo" />
          <slot name="project-selection" />
        </div>
        <div class="col-grow" />
        <div class="col-auto">
          <div class="row items-center">
            <q-btn-dropdown
              id="admin-menu"
              class="q-ma-sm q-pr-none text-h6 dense-dropdown"
              auto-close
              flat
              dense
              square
              no-caps
              :ripple="false"
              dropdown-icon="mdi-menu-down"
              no-icon-animation
              color="grey"
              :label="admin"
              icon="mdi-account-tie"
            >
              <q-list>
                <q-item
                  id="change-password"
                  clickable
                  @click="() => (showChangePasswordDialog = true)"
                >
                  {{ $t("admin-menu.change-password") }}
                </q-item>
              </q-list>
              <q-list>
                <q-item id="logout" clickable :to="{ name: 'login' }">
                  {{ $t("admin-menu.logout") }}
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
        </div>
      </div>

      <q-dialog v-model="showChangePasswordDialog">
        <q-card class="q-dialog-plugin change-password-dialog">
          <q-form ref="passwordForm" class="q-ma-md" greedy>
            <q-card-section>
              <div class="text-h4 q-pb-md">
                {{ $t("change-password-dialog.title") }}
              </div>
            </q-card-section>

            <q-card-section>
              <q-input
                v-model="currentPassword"
                outlined
                :label="$t('change-password-dialog.old-password.label')"
                class="q-pb-md old-password"
                type="password"
                :rules="[
                  (val) => (val && val.length > 0) || $t('input.required'),
                  () =>
                    !currentPasswordInvalid ||
                    $t('change-password-dialog.old-password.on-error'),
                ]"
                hide-bottom-space
                @update:model-value="() => (currentPasswordInvalid = false)"
              />

              <PasswordValidation
                :label="$t('change-password-dialog.new-password.label')"
                class="new-password"
                @update="(val) => (newPassword = val)"
              />
            </q-card-section>

            <q-card-actions align="right">
              <q-btn
                :label="$t('change-password-dialog.cancel-button')"
                color="primary"
                flat
                class="cancel-button"
                @click="hideChangePasswordDialog"
              />
              <q-btn
                :label="$t('change-password-dialog.ok-button')"
                color="primary"
                class="ok-button"
                :disable="!currentPassword || !newPassword"
                :loading="passwordChangeInProgress"
                @click="changePassword"
              />
            </q-card-actions>
          </q-form>
        </q-card>
      </q-dialog>
    </template>

    <template v-if="$slots.navbar" #navbar>
      <slot name="navbar" />
    </template>

    <template #default>
      <div class="full-height">
        <slot />
      </div>
    </template>
  </BaseLayout>
</template>

<script setup lang="ts">
import { inject, ref } from "vue";
import { ApiAuthentication } from "~/api";
import BaseLayout from "~/BaseLayout.vue";
import PasswordValidation from "~/components/PasswordValidation.vue";
import { useRetryDialog } from "~/retryDialog";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

const auth = inject(ApiAuthentication.InjectionKey) as ApiAuthentication;
// navigation guard only allows route when auth.authenticatedAs is valid
const admin = auth.authenticatedAs as string;

const { withRetryDialog } = useRetryDialog();
const quasar = useQuasar();
const { t } = useI18n();

const showChangePasswordDialog = ref(false);
const passwordForm = ref();
const currentPassword = ref<string | undefined>(undefined);
const newPassword = ref<string | undefined>(undefined);
const passwordChangeInProgress = ref(false);
const currentPasswordInvalid = ref(false);

function hideChangePasswordDialog() {
  currentPassword.value = undefined;
  newPassword.value = undefined;
  currentPasswordInvalid.value = false;
  passwordChangeInProgress.value = false;
  showChangePasswordDialog.value = false;
}

async function changePassword() {
  passwordChangeInProgress.value = true;
  const success = await withRetryDialog(async () => {
    return await auth.changePassword(
      currentPassword.value || "",
      newPassword.value || "",
    );
  }).finally(() => (passwordChangeInProgress.value = false));
  if (success) {
    showChangePasswordDialog.value = false;
    quasar.notify({
      type: "positive",
      classes: "admin-password-changed",
      message: t("change-password-dialog.notification.message"),
    });
  } else {
    currentPasswordInvalid.value = true;
    passwordForm.value?.validate();
  }
}
</script>

<style lang="scss">
.project-select .q-btn-dropdown--current {
  cursor: default;
}

.project-select > .q-hoverable:hover > .q-focus-helper {
  background: white !important;
}

.nav-item {
  color: grey;
}

.nav-item.q-router-link--active {
  color: $primary;
}

.app-logo {
  width: 56px;
  padding-right: 6px;
}

.dense-dropdown {
  .q-btn-dropdown__arrow {
    margin-left: 0px;
  }

  .on-left {
    margin-right: 3px;
  }
}
</style>
