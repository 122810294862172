<template>
  <q-table
    class="users-table full-width"
    :rows="users"
    :columns="columns"
    :loading="loading"
    :pagination="{ rowsPerPage: 0, sortBy: 'status' }"
    color="blue"
    row-key="uuid"
    binary-state-sort
    :hide-bottom="!empty"
    :hide-no-data="loading"
  >
    <template v-if="users.length > 0" #top>
      <div class="row full-width">
        <div class="col flex-column">
          <div class="text-h2">
            {{ $t("navigation.users") }}
          </div>
          <div class="text-xs q-mt-sm">
            {{ $t("users.hint") }}
          </div>
        </div>
        <div
          v-if="!empty"
          class="flex-column q-gutter-sm items-end q-mt-md q-mr-md"
          align="right"
        >
          <q-btn
            :label="$t('users.import')"
            color="primary"
            class="import-users-button"
            @click="showImportDialog()"
          />
          <q-btn
            :label="$t('users.add')"
            color="primary"
            class="add-user-button"
            @click="showAddUserDialog()"
          />
          <div v-if="licenseStatus?.canUpgrade">
            {{
              t(
                "users.user-limit",
                !licenseStatus?.valid
                  ? 0
                  : licenseStatus?.remainingFeatures.userCount || 0,
              )
            }}
            <a href="#" class="upgrade-button" @click="showUpgradeDialog()">{{
              t("upgrade-dialog.label")
            }}</a>
          </div>
        </div>
      </div>
    </template>

    <template #no-data>
      <div class="get-started">
        <div class="text-h3 stretch">
          {{ $t("users.get-started.title") }}
        </div>

        <q-img src="/reader.svg" fit="contain" />
        <div>
          <div class="text-h4">
            {{ $t("users.get-started.setup.title") }}
          </div>
          <div>
            {{ $t("users.get-started.setup.text") }}
          </div>
        </div>
        <div>
          <EncryptionKey
            class="q-py-md"
            :value="projectState.settings.value?.credentialKey || ''"
          />
          <a :href="t('links.documentation.config.url')" target="_blank">{{
            $t("users.get-started.setup.encryption-key.where-enter")
          }}</a>
        </div>

        <q-img src="/user-and-app.svg" fit="contain" />
        <div>
          <div class="text-h4">
            {{ $t("users.get-started.invite.title") }}
          </div>
          <div>
            {{ $t("users.get-started.invite.text") }}
          </div>
        </div>
        <div class="buttons-grid">
          <div class="row">
            <q-btn
              :label="$t('users.import')"
              color="primary"
              class="import-users-button col"
              @click="showImportDialog()"
            />
            <q-btn
              :label="$t('users.add')"
              color="primary"
              class="add-user-button col"
              @click="showAddUserDialog()"
            />
          </div>
          <div class="row">
            <q-btn
              :label="$t('users.setup-auto-sync')"
              color="primary"
              class="col setup-auto-sync-button"
              @click="routeToAutoSyncSettings()"
            />
          </div>
        </div>

        <div class="text-bold stretch q-pt-md">
          {{ $t("users.get-started.stuck.text") }}&nbsp;
          <a :href="t('links.support.url')" target="_blank">
            {{ $t("users.get-started.stuck.get-help") }}
          </a>
        </div>
      </div>
    </template>

    <template #header="props">
      <q-tr v-if="!empty" :props="props">
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          <span class="text-bold">{{ col.label }}</span>
          <HelpIcon v-if="col.tooltip" :tooltip="col.tooltip" />
        </q-th>
      </q-tr>
    </template>

    <template #body="props">
      <q-tr :id="`user-row-${props.row.uuid}`" v-flash="props">
        <q-td>
          <CroppedText :text="props.row.firstName" />
        </q-td>

        <q-td>
          <CroppedText :text="props.row.lastName" />
        </q-td>

        <q-td>
          <a :href="'mailto:' + props.row.email">
            <CroppedText :text="props.row.email" />
          </a>
        </q-td>

        <q-td class="text-caption text-black">
          <CroppedText :text="props.row.credentialId" />
        </q-td>

        <q-td auto-width>
          <q-badge
            class="q-pa-xs"
            :color="getStatusColor(props.row)"
            :label="getStatusLabel(props.row)"
          >
            <Tooltip v-html="getStatusTooltip(props.row)" />
          </q-badge>
        </q-td>

        <q-td auto-width>
          <q-btn-dropdown
            v-if="props.row.status !== 'deletion_pending'"
            dropdown-icon="mdi-dots-vertical"
            color="grey"
            flat
            dense
            menu-anchor="top right"
            menu-self="top left"
            :class="`user-menu-${props.row.uuid}`"
          >
            <q-list>
              <q-item
                v-if="props.row.email"
                v-close-popup
                class="q-pa-none"
                style="min-height: 0"
                clickable
                :class="`invite-user-${props.row.uuid}`"
                :disable="props.row.status === 'invitation_pending'"
                @click="inviteUser(props.row)"
              >
                <q-chip icon="mdi-email" color="transparent">
                  {{ $t("users.actions.resend-invitation.label") }}
                </q-chip>
                <Tooltip v-if="props.row.status === 'active'">
                  {{ $t("users.actions.resend-invitation.tooltip.active") }}
                </Tooltip>
                <Tooltip v-else-if="props.row.status === 'invitation_pending'">
                  {{
                    $t(
                      "users.actions.resend-invitation.tooltip.invitation_pending",
                    )
                  }}
                </Tooltip>
              </q-item>

              <q-item
                v-close-popup
                class="q-pa-none"
                style="min-height: 0"
                clickable
                :class="`edit-user-${props.row.uuid}`"
                @click="showEditUserDialog(props.row)"
              >
                <q-chip icon="mdi-pencil" color="transparent">
                  {{ $t("users.actions.edit.label") }}
                </q-chip>
              </q-item>

              <q-item
                v-close-popup
                class="q-pa-none"
                style="min-height: 0"
                clickable
                :class="`delete-user-${props.row.uuid}`"
                @click="deleteUser(props.row)"
              >
                <q-chip
                  icon="mdi-close"
                  text-color="negative"
                  color="transparent"
                >
                  {{ $t("users.actions.delete.label") }}
                </q-chip>

                <Tooltip v-if="getUserUIStatus(props.row) === 'active'">
                  {{ $t("users.actions.delete.tooltip.active") }}
                </Tooltip>
                <Tooltip
                  v-else-if="
                    getUserUIStatus(props.row) === 'activation_pending'
                  "
                >
                  {{ $t("users.actions.delete.tooltip.activation-pending") }}
                </Tooltip>
                <Tooltip
                  v-else-if="
                    getUserUIStatus(props.row) === 'invitation_pending'
                  "
                >
                  {{ $t("users.actions.delete.tooltip.invitation_pending") }}
                </Tooltip>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script setup lang="ts">
import { type User, type UserStatus } from "~/api";
import { ReactiveProjectState } from "~/ReactiveProjectState";
import { computed, inject, type DirectiveBinding, reactive } from "vue";
import { useQuasar } from "quasar";
import { useRetryDialog } from "~/retryDialog";
import { Routing } from "~/routing";
import { useI18n } from "vue-i18n";
import UserDialog from "./UserDialog.vue";
import ImportDialog from "./ImportDialog.vue";
import UpgradeDialog from "./UpgradeDialog.vue";
import CroppedText from "~/components/CroppedText.vue";
import EncryptionKey from "~/components/EncryptionKey.vue";

const { t } = useI18n();
const quasar = useQuasar();
const projectState = inject(
  ReactiveProjectState.InjectionKey,
) as ReactiveProjectState;
const { withRetryDialog } = useRetryDialog();
const routing = inject(Routing.InjectionKey) as Routing;

const UserUIStatusOrder = [
  "activation_pending",
  "invitation_expired",
  "active",
  "offline-24h",
  "offline-4w",
  "invitation_pending",
  "app_reset",
  "deletion_pending",
] as const;

type UserUIStatus = (typeof UserUIStatusOrder)[number];

const left: "left" | undefined = "left";
const columns = [
  {
    name: "first_name",
    label: t("user.first-name"),
    align: left,
    field: (user: User) => user.firstName,
    sortable: true,
  },
  {
    name: "last_name",
    label: t("user.last-name"),
    align: left,
    field: (user: User) => user.lastName,
    sortable: true,
  },
  {
    name: "email",
    label: t("user.email"),
    align: left,
    sortable: true,
    field: (user: User) => user.email,
  },
  {
    name: "credential_id",
    label: t("user.credential_id"),
    align: left,
    field: (user: User) => user.credentialId,
    sortable: true,
    tooltip: t("user.credential_id-hint"),
  },
  {
    name: "status",
    label: t("user.status"),
    align: left,
    field: (user: User) => user.status,
    sortable: true,
    sort: (
      status_a: UserStatus,
      status_b: UserStatus,
      user_a: User,
      user_b: User,
    ): number => {
      const sortIndex = (u: User) =>
        UserUIStatusOrder.indexOf(getUserUIStatus(u));
      return sortIndex(user_a) - sortIndex(user_b);
    },
  },
  {
    name: "actions",
    label: "",
    align: left,
    field: () => "",
  },
];

const users = computed(() => projectState.users.value || []);
const loading = computed(() => projectState.users.value === undefined);
const empty = computed(
  () =>
    projectState.users.value !== undefined &&
    projectState.users.value.length == 0,
);
const licenseStatus = computed(
  () => projectState.settings.value?.licenseStatus,
);

function isExpired(date: Date): boolean {
  return date ? date.getTime() < Date.now() : false;
}

function formatExpiryDate(date: Date): string {
  return `${date.getDate()} ${date.toLocaleString("en-US", { month: "long" })} ${date.getFullYear()}`;
}

function getUserUIStatus(user: User): UserUIStatus {
  switch (user.status) {
    case "active":
      return user.inactivity ? `offline-${user.inactivity}` : "active";
    case "activation_pending":
      return user.invitationExpiryDate && isExpired(user.invitationExpiryDate)
        ? "invitation_expired"
        : "activation_pending";
    default:
      return user.status;
  }
}

function getStatusColor(user: User): string {
  switch (getUserUIStatus(user)) {
    case "activation_pending":
      return "primary";
    case "invitation_expired":
      return "purple";
    case "active":
      return "positive";
    case "offline-24h":
      return "grey";
    case "offline-4w":
      return "grey-1";
    case "invitation_pending":
      return "blue";
    case "app_reset":
      return "pink";
    case "deletion_pending":
      return "warning";
  }
}

function getStatusLabel(user: User) {
  const uiStatus = getUserUIStatus(user);
  switch (uiStatus) {
    case "offline-24h":
    case "offline-4w":
      return t("user.states.offline.label", {
        duration: t(`user.states.offline.durations.${user.inactivity}`),
      });
    default:
      return t(`user.states.${uiStatus}.label`);
  }
}

function getStatusTooltip(user: User): string {
  if (user.status == "activation_pending" && !user.invitationExpiryDate)
    return t("user.states.activation_pending.tooltip-legacy");

  const uiStatus = getUserUIStatus(user);
  switch (uiStatus) {
    case "activation_pending":
    case "invitation_expired":
      return t(`user.states.${uiStatus}.tooltip`, {
        expiry: formatExpiryDate(user.invitationExpiryDate!),
      });

    case "offline-24h":
    case "offline-4w":
      return t("user.states.offline.tooltip", {
        period: t(`user.states.offline.periods.${user.inactivity!}`),
      });

    case "active":
    case "app_reset":
    case "deletion_pending":
    case "invitation_pending":
      return t(`user.states.${uiStatus}.tooltip`);
  }
}

const pendingFlashes: Record<string, User> = reactive({});
function highlightIfPending(el: HTMLElement, user: User) {
  if (
    pendingFlashes[user.uuid] &&
    JSON.stringify(pendingFlashes[user.uuid]) === JSON.stringify(user)
  ) {
    delete pendingFlashes[user.uuid];

    // scroll area is complete table including header but the header is fixed to the top
    // by setting the header height as scroll margin
    // we avoid that the row is hidden behind the header after scrolling.
    el.style.scrollMarginTop = `${document.querySelector("thead")?.clientHeight || 0}px`;
    el.scrollIntoView({ block: "nearest" });

    el.animate(
      {
        backgroundColor: [
          el.style.backgroundColor || "inherit",
          "#d0ecf5",
          el.style.backgroundColor || "inherit",
        ],
        opacity: "0.5",
      },
      { duration: 1000 },
    );
  }
}
const vFlash = {
  mounted: (el: HTMLElement, binding: DirectiveBinding) =>
    highlightIfPending(el, binding.value.row),
  updated: (el: HTMLElement, binding: DirectiveBinding) =>
    highlightIfPending(el, binding.value.row),
};

async function inviteUser(user: User) {
  if (["active"].includes(user.status)) {
    const cont = await new Promise((resolve) =>
      quasar
        .dialog({
          title: t(`users.invite-user-dialog.title`, {
            firstName: user.firstName,
            lastName: user.lastName,
          }),
          class: "confirm-invite-user-dialog",
          message: t(`users.invite-user-dialog.message`),
          ok: {
            label: t(`users.invite-user-dialog.ok-button`),
            class: "ok-button",
          },
          cancel: {
            label: t(`users.invite-user-dialog.cancel-button`),
            class: "cancel-button",
            flat: true,
          },
        })
        .onOk(() => resolve(true))
        .onCancel(() => resolve(false)),
    );
    if (!cont) return;
  }
  await withRetryDialog(() =>
    projectState.sendInvitation(user.uuid).then(() =>
      quasar.notify({
        type: "positive",
        classes: "notify-resent-invite-success",
        message: `${user.firstName} ${user.lastName}`,
        caption:
          user.status === "active"
            ? t("users.invite-resent.success-message.invitation_pending")
            : t("users.invite-resent.success-message.activation_pending"),
      }),
    ),
  );
}

function deleteUser(user: User) {
  const getDialogName = (u: User) => {
    switch (getUserUIStatus(u)) {
      case "activation_pending":
        return "activation_pending";
      case "invitation_pending":
      case "active":
        return "active";
      default:
        return "default";
    }
  };
  const dialog = `users.delete-dialogs.${getDialogName(user)}`;

  quasar
    .dialog({
      title: t(`${dialog}.title`, {
        firstName: user.firstName,
        lastName: user.lastName,
      }),
      class: "confirm-delete-user-dialog",
      message: t(`${dialog}.message`),
      ok: {
        label: t(`${dialog}.ok-button`),
        class: "ok-button",
      },
      cancel: {
        label: t(`${dialog}.cancel-button`),
        class: "cancel-button",
        flat: true,
      },
    })
    .onOk(() => {
      withRetryDialog(
        async () => {
          const maybe_user = await projectState.deleteUser(user.uuid);

          if (!maybe_user) {
            quasar.notify({
              type: "positive",
              classes: "notify-delete-user-success",
              message: `${user.firstName} ${user.lastName}`,
              caption: t(`${dialog}.success-message`),
            });
          } else {
            user.status = maybe_user.status;
            quasar.notify({
              type: "warning",
              textColor: "white",
              classes: "notify-revoke-permissions-pending",
              message: `${user.firstName} ${user.lastName}`,
              caption: t(`${dialog}.success-message`),
            });
          }
        },
        t(`${dialog}.failed-message`, {
          firstName: user.firstName,
          lastName: user.lastName,
        }),
      );
    });
}

function showUpgradeDialog(): void {
  quasar.dialog({
    component: UpgradeDialog,
    componentProps: {
      url: licenseStatus.value?.projectUrl,
    },
  });
}

function showAddUserDialog(): void {
  if (
    licenseStatus.value &&
    (!licenseStatus.value.valid ||
      (licenseStatus.value?.remainingFeatures.userCount !== null &&
        licenseStatus.value?.remainingFeatures.userCount < 1))
  )
    showUpgradeDialog();
  else
    quasar
      .dialog({
        component: UserDialog,
        componentProps: {
          title: t("users.add-user-dialog.title"),
          okLabel: t("users.add-user-dialog.ok-button"),
          cancelLabel: t("users.add-user-dialog.cancel-button"),
          uniqueFields: _uniqueFields(),
        },
      })
      .onOk((userInfo) => {
        withRetryDialog(
          () =>
            projectState
              .addUser(
                userInfo.lastName,
                userInfo.firstName,
                userInfo.email,
                userInfo.credentialId,
              )
              .then((user: User) => {
                projectState.sendInvitation(user.uuid);
                pendingFlashes[user.uuid] = user;
                quasar.notify({
                  type: "positive",
                  classes: "notify-add-user",
                  message: `${user.firstName} ${user.lastName}`,
                  caption: t("users.add-user-dialog.success-message"),
                });
              })
              .catch((e) => {
                if (e.statusCode == 409)
                  quasar.notify({
                    type: "negative",
                    classes: "notify-add-user-conflict",
                    message: t("users.add-user-dialog.failed-message", {
                      firstName: userInfo.firstName,
                      lastName: userInfo.lastName,
                    }),
                  });
                else if (e.statusCode === 591) showUpgradeDialog();
                else throw e;
              }),
          t("users.add-user-dialog.failed-message", {
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
          }),
        );
      });
}

function showEditUserDialog(user: User): void {
  const triggersResend = (newEmail: string) =>
    user.status === "activation_pending" && newEmail !== user.email;
  const triggersAppUpdate = (newId: string) =>
    user.status === "active" && newId !== user.credentialId;

  quasar
    .dialog({
      component: UserDialog,
      componentProps: {
        title: t("users.edit-user-dialog.title"),
        okLabel: (newUser: { email: string; credentialId: string }) => {
          if (triggersResend(newUser.email))
            return t("users.edit-user-dialog.ok-button.resend");
          else if (triggersAppUpdate(newUser.credentialId))
            return t("users.edit-user-dialog.ok-button.update");
          return t("users.edit-user-dialog.ok-button.save");
        },
        cancelLabel: t("users.edit-user-dialog.cancel-button"),
        initialUserInfo: user,
        uniqueFields: _uniqueFields(user.uuid),
        infos: {
          email: (val: string) =>
            triggersResend(val) &&
            t("users.edit-user-dialog.hints.triggers-resend"),
          credentialId: (val: string) =>
            triggersAppUpdate(val) &&
            t("users.edit-user-dialog.hints.triggers-app-update"),
        },
      },
    })
    .onOk((userInfo) => {
      withRetryDialog(
        () =>
          projectState
            .updateUser(
              user.uuid,
              userInfo.lastName,
              userInfo.firstName,
              userInfo.email,
              userInfo.credentialId,
            )
            .then((updatedUser: User) => {
              let caption = "changed";
              if (triggersResend(updatedUser.email)) caption = "resent";
              else if (triggersAppUpdate(updatedUser.credentialId))
                caption = "updated";
              pendingFlashes[updatedUser.uuid] = updatedUser;

              quasar.notify({
                type: "positive",
                classes: "notify-edit-user",
                message: `${updatedUser.firstName} ${updatedUser.lastName}`,
                caption: t(`users.edit-user-dialog.success-message.${caption}`),
              });
            })
            .catch((e) => {
              if (e.statusCode == 409)
                quasar.notify({
                  type: "negative",
                  classes: "notify-edit-user-conflict",
                  message: t("users.edit-user-dialog.failed-message", {
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                  }),
                });
              else throw e;
            }),
        t("users.edit-user-dialog.failed-message", {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
        }),
      );
    });
}

function _uniqueFields(
  exclude_uuid: string | undefined = undefined,
): { email: string; credentialId: string }[] {
  return users.value
    .filter((u) => u.status !== "deletion_pending")
    .filter((u) => exclude_uuid === undefined || u.uuid != exclude_uuid)
    .map((u) => {
      return { email: u.email, credentialId: u.credentialId };
    });
}

function showImportDialog(): void {
  quasar.dialog({
    component: ImportDialog,
    componentProps: { projectState, onUpgrade: showUpgradeDialog },
  });
}

function routeToAutoSyncSettings(): void {
  routing.settings({ highlight: "auto-sync-section" });
}
</script>

<style lang="sass">
.users-table
  max-height: 100%

  padding-bottom: 30px
  .q-table__top
    padding-top: 30px
  .q-table__top,
  .q-table__middle
    padding-inline: 30px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    background-color: #fff

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0

.get-started
  display: grid
  height: 100%
  grid-template-columns: minmax(auto, 120px) auto max-content
  max-width: 1200px
  margin: auto
  padding: 3em 3em calc(3em - 30px)
  gap: 4em

  .stretch
    grid-column: 1 / 4
    text-align: center

  .buttons-grid
    display: grid
    row-gap: 1em
    grid-template-rows: 1fr 1fr

    div
      column-gap: 1em
</style>
