<template>
  <q-list>
    <q-item tag="label" class="q-pa-none default-settings">
      <q-item-section avatar>
        <q-radio
          :model-value="mode"
          val="default"
          size="xs"
          @update:model-value="setDefault()"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label>
          {{ $t("mail-settings-editor.default") }}
        </q-item-label>
      </q-item-section>
    </q-item>

    <q-item tag="label" class="q-pa-none custom-settings">
      <q-item-section avatar>
        <q-radio
          :model-value="mode"
          val="custom"
          size="xs"
          @update:model-value="configure()"
        />
      </q-item-section>
      <q-item-section>
        <q-item-label>
          {{
            $t("mail-settings-editor.custom", {
              sender:
                props.settings?.sender ||
                $t("mail-settings-editor.custom-no-sender"),
            })
          }}
          <a
            v-if="props.settings"
            target="_blank"
            class="edit-button"
            @click="configure()"
          >
            {{ $t("mail-settings-editor.custom-edit") }}
          </a>
          <a
            v-else
            :href="$t('links.documentation.mail-settings.url')"
            target="_blank"
          >
            {{ $t("mail-settings-editor.custom-why") }}
          </a>
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-list>

  <q-dialog v-model="showDialog">
    <q-card
      class="q-dialog-plugin mail-settings-dialog"
      style="max-width: 80vw; width: 600px"
    >
      <q-form ref="formRef" class="q-ma-md" greedy>
        <q-card-section>
          <div class="text-h4 title">
            {{ $t("mail-settings-editor.dialog.title") }}
          </div>
          <div class="text-caption">
            {{ $t("mail-settings-editor.dialog.caption") }}
            <a
              :href="$t('links.documentation.mail-settings.url')"
              target="_blank"
            >
              {{ $t("mail-settings-editor.dialog.caption-howto") }}
            </a>
          </div>
        </q-card-section>

        <q-card-section>
          <q-input
            v-model="hostname"
            outlined
            :label="$t('mail-settings-editor.dialog.hostname')"
            class="q-pb-md hostname"
            :rules="[(val) => (val && val.length > 0) || $t('input.required')]"
            hide-bottom-space
          />
          <q-input
            v-model="username"
            outlined
            :label="$t('mail-settings-editor.dialog.username')"
            class="q-pb-md username"
          />
          <q-input
            v-model="password"
            outlined
            input-class="undisclosed"
            :label="$t('mail-settings-editor.dialog.password')"
            class="q-pb-md password"
          />
          <q-input
            v-model="sender"
            outlined
            :label="$t('mail-settings-editor.dialog.sender')"
            class="q-pb-md sender"
            type="email"
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val, rules) =>
                rules.email(val) ||
                rules.email(emailFromMailStr(val)) ||
                $t('input.enter-valid-email'),
            ]"
            hide-bottom-space
            :placeholder="$t('mail-settings-editor.dialog.sender-placeholder')"
            stack-label
          />
          <q-select
            v-model="security"
            outlined
            :label="$t('mail-settings-editor.dialog.security')"
            class="q-pb-md security"
            style="width: 150px"
            :options="[
              { value: 'none', label: 'None' },
              { value: 'tls', label: 'TLS' },
              { value: 'starttls', label: 'STARTTLS' },
            ]"
            map-options
            emit-value
          />
          <q-input
            v-model.number="port"
            type="number"
            outlined
            input-class="no-arrows"
            :label="$t('mail-settings-editor.dialog.port')"
            class="q-pb-md port"
            style="width: 100px"
          />
          <q-input
            v-model="valediction"
            outlined
            type="textarea"
            :label="$t('mail-settings-editor.dialog.valediction')"
            class="q-pb-md valediction"
            :hint="$t('mail-settings-editor.dialog.valediction-hint')"
            hide-bottom-space
            :placeholder="
              $t('mail-settings-editor.dialog.valediction-placeholder')
            "
            stack-label
            :rows="
              $t('mail-settings-editor.dialog.valediction-placeholder').split(
                '\n',
              ).length
            "
          />
          <q-input
            v-model="footer"
            outlined
            type="textarea"
            :label="$t('mail-settings-editor.dialog.footer')"
            class="q-pb-md footer"
            :hint="$t('mail-settings-editor.dialog.footer-hint')"
            hide-bottom-space
            :placeholder="$t('mail-settings-editor.dialog.footer-placeholder')"
            stack-label
            :rows="
              $t('mail-settings-editor.dialog.footer-placeholder').split('\n')
                .length
            "
          />
          <q-input
            v-model="sendTo"
            outlined
            :label="$t('mail-settings-editor.dialog.send-test')"
            :rules="[
              (val) =>
                !receiverRequired ||
                (val && val.length > 0) ||
                $t('input.required'),
              (val, rules) =>
                !receiverRequired ||
                rules.email(val) ||
                $t('input.enter-valid-email'),
            ]"
            class="q-pb-md send-to"
          >
            <template #after>
              <q-btn
                color="primary"
                class="send-button"
                :label="$t('mail-settings-editor.dialog.send-button')"
                @click="onSend"
              />
            </template>
          </q-input>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            :label="$t('mail-settings-editor.dialog.cancel-button')"
            color="primary"
            flat
            class="cancel-button"
            @click="onCancel()"
          />
          <q-btn
            :label="$t('mail-settings-editor.dialog.ok-button')"
            color="primary"
            class="ok-button"
            @click="onOk"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import type { MailSettings } from "~/api";
import { useQuasar } from "quasar";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  settings?: MailSettings;
}>();

const emit = defineEmits<{
  update: [settings: MailSettings | null];
  send: [{ receiver: string; settings: MailSettings }];
}>();

const mode = computed(() => (props.settings ? "custom" : "default"));

const quasar = useQuasar();
const { t } = useI18n();

const formRef = ref();
const showDialog = ref(false);
const receiverRequired = ref(false);

const hostname = ref("");
const username = ref("");
const password = ref("");
const sender = ref("");
const security = ref<"none" | "starttls" | "tls">("starttls");
const port = ref<number | undefined>(undefined);
const valediction = ref("");
const footer = ref("");
const sendTo = ref("");

async function validate(opts?: {
  receiverRequired: boolean;
}): Promise<boolean> {
  receiverRequired.value = opts?.receiverRequired || false;
  const valid = await formRef.value.validate();
  receiverRequired.value = false;
  return valid;
}

function emailFromMailStr(email: string): string | undefined {
  const captures = /[^<]+ <([^>]+)>/.exec(email);
  if (captures !== null && captures.length == 2) return captures[1];
}

function buildMailSettings(): MailSettings {
  return {
    hostname: hostname.value,
    username: username.value || undefined,
    password: password.value || undefined,
    sender: sender.value,
    security: security.value,
    port: port.value || undefined,
    footer: footer.value,
    valediction: valediction.value,
  };
}

function setDefault() {
  emit("update", null);
}

function configure() {
  hostname.value = props.settings?.hostname || "";
  username.value = props.settings?.username || "";
  password.value = props.settings?.password || "";
  sender.value = props.settings?.sender || "";
  port.value = props.settings?.port || undefined;
  security.value = props.settings?.security || "starttls";
  footer.value = props.settings?.footer || "";
  valediction.value = props.settings?.valediction || "";
  sendTo.value = "";
  showDialog.value = true;
}

async function onSend() {
  if (await validate({ receiverRequired: true }))
    emit("send", { receiver: sendTo.value, settings: buildMailSettings() });
  quasar.notify({
    type: "positive",
    message: t("mail-settings-editor.dialog.send-notification"),
  });
}

async function onOk() {
  if (await validate()) {
    emit("update", buildMailSettings());
    showDialog.value = false;
  }
}

function onCancel() {
  showDialog.value = false;
}
</script>
