<template>
  <q-dialog
    ref="dialogRef"
    :model-value="true"
    class="user-dialog"
    @hide="onDialogHide"
  >
    <q-card class="q-dialog-plugin">
      <q-form ref="formRef" class="q-gutter-md" greedy>
        <q-card-section>
          <div class="text-h2 q-mt-sm q-mb-xs title">
            {{ props.title }}
          </div>
        </q-card-section>

        <q-card-section>
          <UserDetailInput
            v-model="userInfo.firstName"
            autofocus
            :label="$t('user.first-name')"
            :rules="[(val) => (val && val.length > 0) || $t('input.required')]"
            class="first-name"
            :infos="props.infos?.firstName ? [props.infos.firstName] : []"
          />

          <UserDetailInput
            v-model="userInfo.lastName"
            :label="$t('user.last-name')"
            :rules="[(val) => (val && val.length > 0) || $t('input.required')]"
            class="last-name"
            :infos="props.infos?.lastName ? [props.infos.lastName] : []"
          />
          <UserDetailInput
            v-model="userInfo.email"
            :label="$t('user.email')"
            type="email"
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val, rules) => rules.email(val) || $t('input.enter-valid-email'),
              (val) =>
                props.uniqueFields
                  .map((u) => u.email.toLowerCase())
                  .indexOf(val.toLowerCase()) == -1 ||
                $t('input.non-unique-email'),
            ]"
            class="email"
            :infos="props.infos?.email ? [props.infos.email] : []"
          />

          <UserDetailInput
            v-model="userInfo.credentialId"
            :label="$t('user.credential_id')"
            :hint="$t('user.credential_id-hint')"
            :rules="[
              (val) => (val && val.length > 0) || $t('input.required'),
              (val) =>
                val.length <= 32 ||
                $t('input.character-limit', { value: '32' }),
              (val) =>
                val.split('').every((c: string) => c.charCodeAt(0) <= 127) ||
                $t('input.character-limit', { value: 'ASCII' }),
              (val) =>
                props.uniqueFields.map((u) => u.credentialId).indexOf(val) ==
                  -1 || $t('input.non-unique-credential_id'),
            ]"
            :warnings="[
              (val) => !isNaN(Number(val)) || $t('input.credential_id-decimal'),
            ]"
            class="credential_id"
            :infos="props.infos?.credentialId ? [props.infos.credentialId] : []"
            @keyup.enter="onOk"
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            :label="props.cancelLabel"
            color="primary"
            flat
            class="cancel-button"
            @click="onDialogCancel()"
          />
          <q-btn
            :label="okLabel"
            color="primary"
            class="ok-button"
            @click="onOk"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useDialogPluginComponent } from "quasar";
import { ref, computed } from "vue";

import UserDetailInput from "~/components/UserDetailInput.vue";

type UserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  credentialId: string;
};

defineEmits({ ...useDialogPluginComponent.emitsObject });
const props = defineProps<{
  title: string;
  initialUserInfo?: UserInfo;
  okLabel: string | ((user: UserInfo) => string);
  cancelLabel: string;
  uniqueFields: { email: string; credentialId: string }[];
  infos?: Record<keyof UserInfo, (val: string) => boolean | string>;
}>();

const formRef = ref();

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const userInfo = ref({
  firstName: props.initialUserInfo?.firstName || "",
  lastName: props.initialUserInfo?.lastName || "",
  email: props.initialUserInfo?.email || "",
  credentialId: props.initialUserInfo?.credentialId || "",
});

const okLabel = computed(() => {
  if (typeof props.okLabel === "string") return props.okLabel;
  else return props.okLabel(userInfo.value);
});

function onOk() {
  formRef.value.validate().then((success: boolean) => {
    if (success) onDialogOK(userInfo.value);
  });
}
</script>
