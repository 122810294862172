import { type InjectionKey } from "vue";
import { decodeToken } from "~/jwt";
import { ApiError, AutoSyncError, AutoSyncIssue } from "~/errors";

export type UserInactivity = "24h" | "4w";

interface ApiUser {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  credential_id: string;
  status:
    | "activation_pending"
    | "active"
    | "invitation_pending"
    | "app_reset"
    | "deletion_pending";
  inactivity?: UserInactivity;
  invitation_expiry_date?: string;
}

export interface LicenseStatus {
  valid: boolean;
  projectUrl: string;
  canUpgrade: boolean;
  remainingFeatures: {
    userCount: number | null;
  };
  availableFeatures: {
    userCount: number | null;
  };
}

export interface SelfserviceInfo {
  url: string;
  usedCredentials: [number, number] | null;
}

export interface ProjectSettings {
  credentialKey: string;
  userManagementMode: "manual" | "autosync" | "selfservice";
  autoForwardSupportRequests: boolean;
  licenseStatus: LicenseStatus;
  lastAutoSync: Date | null;
  selfservice: SelfserviceInfo | null;
  mailSettings: MailSettings | null;
}

export interface ProjectUpdate {
  autoForwardSupportRequests: boolean;
  autosync: boolean;
  selfservice: boolean;
  mailSettings: MailSettings | null;
}

export type ProjectAdmin = {
  email: string;
  official: boolean;
  self: boolean;
  active: boolean;
};

export type UserStatus =
  | "activation_pending"
  | "active"
  | "invitation_pending"
  | "app_reset"
  | "deletion_pending";

export interface User {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
  credentialId: string;
  status: UserStatus;
  inactivity?: UserInactivity;
  invitationExpiryDate?: Date;
}

export interface MailSettings {
  hostname: string;
  username?: string;
  password?: string;
  sender: string;
  security?: "none" | "starttls" | "tls";
  port?: number;
  footer: string;
  valediction: string;
}

function mapApiUser(apiUser: ApiUser): User {
  return {
    uuid: apiUser.uuid,
    firstName: apiUser.first_name,
    lastName: apiUser.last_name,
    email: apiUser.email,
    credentialId: apiUser.credential_id,
    status: apiUser.status,
    inactivity: apiUser.inactivity,
    invitationExpiryDate: apiUser.invitation_expiry_date
      ? new Date(apiUser.invitation_expiry_date)
      : undefined,
  };
}

export class ApiAuthentication extends EventTarget {
  public static InjectionKey = Symbol() as InjectionKey<ApiAuthentication>;

  public readonly baseUrl: string;
  private readonly accessTokenStorageKey = "access_token";
  constructor(baseUrl: string | undefined = undefined) {
    super();
    this.baseUrl =
      baseUrl || import.meta.env.VITE_API_ENDPOINT || window.location.origin;
  }

  public get authenticationInfo():
    | { admin: string; expired?: boolean }
    | undefined {
    const token = this.getToken();
    if (token === null) return undefined;

    const decoded = decodeToken(token);
    if (decoded === undefined) return undefined;

    const [domain, decodedAdminMail] = decoded.sub.toString().split("/", 2);
    if (domain != "admins") return undefined;
    if (decodedAdminMail === undefined) return undefined;

    return {
      admin: decodeURIComponent(decodedAdminMail),
      expired: decoded.expired,
    };
  }

  public get authenticatedAs(): string | undefined {
    const info = this.authenticationInfo;
    if (info === undefined) return undefined;
    if (info.expired || info.expired === undefined) return undefined;
    return info.admin;
  }

  public set onunauthorized(handler: (e: Event) => void) {
    this.addEventListener("unauthorized", handler);
  }

  async updateToken(username: string, password: string): Promise<void> {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    const response = await this.json("/token", {
      method: "POST",
      body: formData,
    });
    this.setToken(response["access_token"]);
  }

  public revokeToken() {
    this.deleteToken();
  }

  public projects(): Promise<string[]> {
    return this.json("/projects");
  }

  public async requestVerificationMail(
    username: string,
    password: string,
  ): Promise<void> {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    await this.fetch("/admins/verification", {
      method: "POST",
      body: formData,
    });
  }

  public async createAdmin(
    username: string,
    password: string,
    token: string,
  ): Promise<boolean> {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("activation_token", token);
    const response = await this.fetch(
      "/admins",
      {
        method: "POST",
        body: formData,
      },
      [410],
    );
    return response.status !== 410;
  }

  public async requestPasswordReset(username: string) {
    const formData = new FormData();
    formData.append("username", username);
    await this.fetch("/admins/password/reset", {
      method: "POST",
      body: formData,
    });
  }

  public async passwordReset(
    username: string,
    password: string,
    token: string,
  ) {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("reset_token", token);
    const response = await this.fetch(
      "/admins/password",
      {
        method: "POST",
        body: formData,
      },
      [410],
    );
    return response.status !== 410;
  }

  public async changePassword(
    currentPassword: string,
    newPassword: string,
  ): Promise<boolean> {
    const formData = new FormData();
    formData.append("current_password", currentPassword);
    formData.append("new_password", newPassword);
    const response = await this.fetch(
      "/admins/password/change",
      {
        method: "POST",
        body: formData,
      },
      [409],
    );
    return response.ok;
  }

  public async createProject(settings: {
    projectName: string;
    autoForwardSupportRequest: boolean;
    selfservice: boolean;
    mailSettings: MailSettings | null;
  }): Promise<boolean> {
    const repsonse = await this.fetch(
      `/projects/${settings.projectName}`,
      {
        method: "POST",
        body: JSON.stringify({
          auto_forward_support_request: settings.autoForwardSupportRequest,
          selfservice: settings.selfservice,
          mail_settings: settings.mailSettings,
        }),
        headers: {
          "content-type": "application/json",
        },
      },
      [409],
    );
    return repsonse.ok;
  }

  async sendTestMail(
    receiver: string,
    settings: MailSettings,
    projectName: string,
  ) {
    await this.fetch("/test-mail", {
      method: "POST",
      body: JSON.stringify({ receiver, settings, project_name: projectName }),
      headers: {
        "content-type": "application/json",
      },
    });
  }

  public async fetch(
    path: string,
    opts?: RequestInit,
    ignores_status: number[] = [],
  ): Promise<Response> {
    const token = this.getToken();
    if (token !== null) {
      if (opts === undefined) opts = {};
      if (opts.headers === undefined) opts.headers = {};
      // @ts-ignore
      opts.headers["authorization"] = `Bearer ${token}`;
    }

    const response = await window
      .fetch(encodeURI(`${this.baseUrl}/api${path}`), opts)
      .catch((e: Error) => {
        throw new ApiError(e.message);
      });
    if (response.status === 401) this.raiseUnauthorized();
    if (!response.ok && !ignores_status.includes(response.status))
      throw new ApiError(response.statusText, response.status);
    return response;
  }

  public raiseUnauthorized() {
    const handled = !this.dispatchEvent(
      new Event("unauthorized", { cancelable: true }),
    );
    throw new ApiError("authorization required", 401, handled);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async json(path: string, opts?: RequestInit): Promise<any> {
    const response = await this.fetch(path, opts);
    return await response.json();
  }

  public getToken(): string | null {
    return window.localStorage.getItem(this.accessTokenStorageKey);
  }

  private setToken(token: string) {
    window.localStorage.setItem(this.accessTokenStorageKey, token);
  }

  private deleteToken() {
    window.localStorage.removeItem(this.accessTokenStorageKey);
  }
}

export class ProjectApi extends EventTarget {
  public static InjectionKey = Symbol() as InjectionKey<ProjectApi>;

  private ws: WebSocket | undefined = undefined;

  constructor(
    public readonly project: string,
    private auth: ApiAuthentication,
  ) {
    super();
  }

  public set onupdate(handler: (e: Event) => void) {
    this.addEventListener("update", handler);
  }

  public set onconnectionlost(handler: (e: Event) => void) {
    this.addEventListener("connectionlost", handler);
  }

  public startWatching() {
    if (this.ws !== undefined) return;

    const wsUrl = new URL(this.auth.baseUrl);
    wsUrl.protocol = wsUrl.protocol === "http:" ? "ws:" : "wss:";
    wsUrl.pathname = `/api/projects/${this.project}/updates`;

    this.ws = new WebSocket(wsUrl.toString());

    this.ws.onopen = () => {
      const token = this.auth.getToken();
      if (this.ws && token !== null) this.ws.send(token);
    };

    this.ws.onmessage = () => this.dispatchEvent(new Event("update"));

    this.ws.onclose = (event) => {
      this.stopWatching();
      if (event.code === 4001) {
        try {
          this.auth.raiseUnauthorized();
        } catch (e) {
          if (!(e instanceof ApiError && e.handled))
            this.dispatchEvent(new Event("connectionlost"));
        }
      } else if (event.code !== 1000) {
        this.dispatchEvent(new Event("connectionlost"));
      }
    };
  }

  public stopWatching() {
    if (this.ws === undefined) return;
    if (this.ws.readyState < this.ws.CLOSING) this.ws.close(1000);
    this.ws = undefined;
  }

  async delete(): Promise<void> {
    this.stopWatching();
    await this.auth.fetch(`/projects/${this.project}`, {
      method: "DELETE",
    });
  }

  async settings(): Promise<ProjectSettings> {
    const settings = await this.auth.json(`/projects/${this.project}/settings`);
    return {
      userManagementMode: settings.user_management_mode,
      credentialKey: settings.credential_key || "",
      autoForwardSupportRequests: !!settings.auto_forward_support_request,
      licenseStatus: {
        valid: settings.license_status.valid,
        projectUrl: settings.license_status.project_url,
        canUpgrade: settings.license_status.can_upgrade,
        remainingFeatures: {
          userCount:
            typeof settings.license_status.remaining_features?.user_count ===
            "number"
              ? settings.license_status.remaining_features?.user_count
              : null,
        },
        availableFeatures: {
          userCount:
            typeof settings.license_status.available_features?.user_count ===
            "number"
              ? settings.license_status.available_features?.user_count
              : null,
        },
      },
      lastAutoSync: settings.last_autosync
        ? new Date(settings.last_autosync)
        : null,
      selfservice: settings.selfservice
        ? {
            url: settings.selfservice.url as string,
            usedCredentials: settings.selfservice.used_credentials as
              | [number, number]
              | null,
          }
        : null,
      mailSettings: settings.mail_settings
        ? {
            hostname: settings.mail_settings.hostname,
            username: settings.mail_settings.username,
            password: settings.mail_settings.password,
            sender: settings.mail_settings.sender,
            port: settings.mail_settings.port,
            security: settings.mail_settings.security,
            footer: settings.mail_settings.footer,
            valediction: settings.mail_settings.valediction,
          }
        : null,
    };
  }

  async updateSettings(update: Partial<ProjectUpdate>): Promise<void> {
    await this.auth.fetch(`/projects/${this.project}/settings`, {
      method: "PATCH",
      body: JSON.stringify({
        auto_forward_support_request: update.autoForwardSupportRequests,
        autosync: update.autosync,
        selfservice: update.selfservice,
        mail_settings:
          update.mailSettings === null ? "system" : update.mailSettings,
      }),
      headers: {
        "content-type": "application/json",
      },
    });
  }

  async admins(): Promise<ProjectAdmin[]> {
    const admins = await this.auth.json(`/projects/${this.project}/admins`);
    return admins.map(
      (admin: {
        email: string;
        official: boolean;
        auth: boolean;
        active: boolean;
      }) => ({
        email: admin.email,
        official: admin.official,
        self: admin.auth,
        active: admin.active,
      }),
    );
  }

  async addAdmin(email: string): Promise<void> {
    await this.auth.fetch(`/projects/${this.project}/admins`, {
      method: "POST",
      body: email,
    });
  }

  async removeAdmin(email: string): Promise<void> {
    await this.auth.fetch(`/projects/${this.project}/admins/${email}`, {
      method: "DELETE",
    });
  }

  async makeOfficialContact(email: string): Promise<boolean> {
    const response = await this.auth.fetch(
      `/projects/${this.project}/admins/official-contact`,
      {
        method: "POST",
        body: email,
      },
      [404, 403],
    );
    return response.ok;
  }

  async autosyncConfig(configType: "task"): Promise<File> {
    const response = await this.auth.fetch(
      `/projects/${this.project}/sync/${configType}`,
    );
    const filename_match = response.headers
      .get("content-disposition")
      ?.match(/filename="([^"]+)"/);
    const filename =
      filename_match && filename_match.length >= 1
        ? filename_match[1]
        : undefined;
    return new File([await response.blob()], filename || "unkown");
  }

  async autosync(file: File): Promise<void> {
    const upgradeRequired = 591;
    const statusCodesForIssues = [400, 415, upgradeRequired];

    const formData = new FormData();
    formData.append(
      "csv_file",
      new File([file], file.name, { type: "text/csv" }),
    );
    const response = await this.auth.fetch(
      `/projects/${this.project}/sync`,
      {
        method: "POST",
        body: formData,
      },
      statusCodesForIssues,
    );
    if (statusCodesForIssues.includes(response.status)) {
      const json = await response.json();
      const issues = json?.detail;
      if (!issues) throw new ApiError(response.statusText, response.status);
      throw new AutoSyncError(
        issues.map(
          (i: AutoSyncIssue) => new AutoSyncIssue(i.location, i.message),
        ),
        response.status === upgradeRequired,
      );
    }
  }

  async users(): Promise<User[]> {
    const users = await this.auth.json(`/projects/${this.project}/users`);
    return users.map(mapApiUser);
  }

  async addUser(
    lastName: string,
    firstName: string,
    email: string,
    credentialId: string,
  ): Promise<User> {
    const apiUser: ApiUser = await this.auth.json(
      `/projects/${this.project}/users`,
      {
        method: "POST",
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
          credential_id: credentialId,
        }),
        headers: {
          "content-type": "application/json",
        },
      },
    );
    return mapApiUser(apiUser);
  }

  async updateUser(
    userUuid: string,
    lastName: string,
    firstName: string,
    email: string,
    credentialId: string,
  ): Promise<User> {
    const apiUser: ApiUser = await this.auth.json(
      `/projects/${this.project}/users/${userUuid}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          first_name: firstName,
          last_name: lastName,
          email: email,
          credential_id: credentialId,
        }),
        headers: {
          "content-type": "application/json",
        },
      },
    );
    return mapApiUser(apiUser);
  }

  async deleteUser(uuid: string): Promise<User | null> {
    const response = await this.auth.fetch(
      `/projects/${this.project}/users/${uuid}`,
      {
        method: "DELETE",
      },
      [404],
    );
    if (response.status == 202) return mapApiUser(await response.json());
    return null;
  }

  async sendInvitation(user_uuid: string) {
    await this.auth.fetch(`/projects/${this.project}/invitations`, {
      method: "POST",
      body: JSON.stringify(user_uuid),
      headers: {
        "content-type": "application/json",
      },
    });
  }

  async sendTestMail(receiver: string, settings: MailSettings) {
    await this.auth.sendTestMail(receiver, settings, this.project);
  }
}
